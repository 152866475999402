<template>
  <ul :class="ulClass">
    <top-menu-item
      v-for="(item, index) in menu"
      :key="index"
      :item="item"
      :is-submenu-list="isSubmenuList"
      :compact="compact"
      @menu-click="menuClick"
      :isMobile="isMobile"
    />
  </ul>
</template>

<script>
import { computed } from 'vue'
import TopMenuItem from '@/components/TopMenuItem'

export default {
  name: 'TopMenuList',
  components: {
    TopMenuItem
  },
  emits: ['menu-click'],
  props: {
    isSubmenuList: Boolean,
    compact: Boolean,
    isMobile: {
      type: Boolean,
      default: false
    },
    menu: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { emit }) {
    const menuClick = (event, item) => {
      emit('menu-click', event, item)
    }

    const ulClass = computed(() => {
      let base = 'flex flex-wrap -mb-px text-sm font-medium text-center text-black'

      if (props.isMobile) { 
        base = 'flex flex-col p-4 pt-6 md:p-0 mt-12 font-medium border border-gray-100 rounded-lg bg-white md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700'
      }

      return base
    })

    return {
      menuClick,
      ulClass
    }
  }
}
</script>
