<template>
  <volumes-and-clicks-chart-card v-if="selectedUser && 'chartComponent' in column && column.chartComponent === 'VolumesAndClicksChartCard'" :selectedUser="selectedUser" :type="column.chartType"/>
  <base-chart v-else :column="column"/>
</template>

<script setup>
import BaseChart from './charts/BaseChart'
import VolumesAndClicksChartCard from '@/components/user/VolumesAndClicksChartCard'
import { defineProps, onMounted, ref, computed } from 'vue'
import Repository from '@/repositories/RepositoryFactory'
import { useStore } from 'vuex'

const AuthRepository = Repository.get('auth')
const store = useStore()

const selectedUser = computed(() => {
  return {
    volumes: store.state.auth.volumes,
    clicks: store.state.auth.clicks,
    email: store.state.auth.email
  }
})

const props = defineProps({
  column: {
    type: Object
  }
})

</script>
