<template>
  <div class="fixed inset-0 flex flex-col-reverse p-9 overflow-hidden z-100 pointer-events-none">
    <snackbar-bar v-for="message in messages" :key="message.ts" :active="true" :message="message" @expired="expired"/>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import SnackbarBar from '@/components/SnackbarBar'

export default {
  name: 'Snackbar',
  components: {
    SnackbarBar
  },
  props: {
    active: Boolean
  },
  setup () {
    const store = useStore()

    const messages = computed(() => store.state.display.messages)

    const expired = () => {
      store.commit('display/shiftMessage')
    }

    return {
      messages,
      expired
    }
  }
}
</script>
