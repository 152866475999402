import { mdiDelete, mdiDialpad, mdiFileDocumentMultipleOutline } from '@mdi/js'

export const buttonMenuOptions = [
  [
    {
      id: 1,
      icon: mdiDialpad,
      label: 'Option One'
    },
    {
      id: 2,
      icon: mdiFileDocumentMultipleOutline,
      label: 'Option Two'
    }
  ],
  [
    {
      id: 3,
      icon: mdiDelete,
      label: 'Last'
    }
  ]
]
