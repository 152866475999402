import Client from './clients/Client'
const resource = '/email'

export default {
  getCaptcha () {
    return Client.get(`${resource}/captcha`)
  },
  submitContactForm (payload) {
    return Client.post(`${resource}/contact`, payload)
  },
  subscribeNewsletter (payload) {
    return Client.post(`${resource}/subscribe`, payload)
  }
}
