<template>
  <main-section>
    <dynamic-render :userDate="userDate" :includeDatePicker="includeDatePicker" :refreshKey="refreshKey" v-if="showDynamicRender" :typeSchema="typeSchemaRender" @updateDate="updateDate"/>
  </main-section>
</template>

<script setup>
import { ref, computed, watch, nextTick, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import filters from '@/filters'
import MainSection from '@/components/MainSection'
import DynamicRender from '@/components/DynamicRender'

const { t } = useI18n()
const store = useStore()
const route = useRoute()

const userDate = ref(null)
const typeSchema = ref({})
const requiresAuth = ref(false) // ref(props.requiresAuth || false)
const refreshKey = ref(0)
const titleStack = ref([])
const isFetchingUser = computed(() => store.state.auth.isFetchingUser)
const isAuthenticated = computed(() => store.state.auth.isAuthenticated)
const typeSchemaRender = ref([])
const includeDatePicker = ref(false)
const showDynamicRender = ref(true)
const isLg = computed(() => store.state.isLg)
const isMd = computed(() => store.state.isMd)

watch(route, () => {
  typeSchema.value = route.meta.typeSchema || {}
  requiresAuth.value = route.meta.requiresAuth || false 
  fillDashboard()
})

watch([isLg, isMd, isFetchingUser], () => {
  typeSchema.value = route.meta.typeSchema || {}
  fillDashboard()
})

onMounted(() => {
  typeSchema.value = route.meta.typeSchema || {}
  fillDashboard()
})

watch(() => typeSchema, () => {
  fillDashboard()
})

const fillDashboard = () => {
  requiresAuth.value = route.meta.requiresAuth || false
  refreshKey.value++
  typeSchemaRender.value = []
  showDynamicRender.value = false

  if (isFetchingUser.value === true) {
    return false
  }
  // console.log(requiresAuth, isAuthenticated.value)
  if (requiresAuth.value === true && isAuthenticated.value === false) {
    return false
  } else { 
    titleStack.value = 'title' in typeSchema.value ? [t('menu_home'), t(typeSchema.value.title)] : []
    includeDatePicker.value = 'includeDatePicker' in typeSchema.value
    typeSchemaRender.value = filters.fillDates(typeSchema.value.data)

    nextTick(() => {
      showDynamicRender.value = true
    })
  }
}

const updateDate = (item) => { 
  const newTypeSchema = route.meta.typeSchema

  typeSchema.value.data.forEach(row => {
    for (const [key, column] of Object.entries(row.columns)) {
      if (column.fetchOptions.start_date !== undefined || column.fetchOptions.end_date !== undefined) {
        column.fetchOptions.start_date = item[0]
        column.fetchOptions.end_date = item[1]
      }

      newTypeSchema[key] = column
    }
  })
  
  userDate.value = item[0].split('T')[0]
  typeSchema.value = newTypeSchema
  fillDashboard()
}
</script>
