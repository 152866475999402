<template>
  <div
    v-if="!isDismissed"
    :class="componentClass"
    class="px-3 py-3 md:py-3 mx-6 md:mx-0 last:mb-0 border transition-colors duration-150"
  >
    <level>
      <div class="flex flex-col md:flex-row items-start">
        <icon v-if="icon" :path="icon" w="w-10 md:w-5" h="h-10 md:h-5" size="24" class="md:mr-2" />
        <span class="md:text-left"><slot /></span>
      </div>
      <slot v-if="hasRightSlot" name="right" />
      <jb-button v-else-if="hasDismissButton" :icon="mdiClose" @click="dismiss" :outline="outline" small />
    </level>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { mdiClose } from '@mdi/js'
import { colorsBg, colorsBorders, colorsOutline } from '@/colors.js'
import Level from '@/components/Level'
import Icon from '@/components/Icon'
import JbButton from '@/components/JbButton'

export default {
  name: 'Notification',
  components: {
    Icon,
    Level,
    JbButton
  },
  props: {
    icon: String,
    outline: Boolean,
    hasDismissButton: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      required: true
    }
  },
  setup (props, { slots }) {
    const store = useStore()

    const componentClass = computed(() => props.outline
      ? colorsOutline[props.color]
      : [...colorsBg[props.color], ...colorsBorders[props.color]])

    const isDismissed = ref(false)

    const dismiss = () => {
      isDismissed.value = true
      store.dispatch('display/pushMessage', 'Done! Notification Dismissed')
    }

    const hasRightSlot = computed(() => slots.right)

    return {
      componentClass,
      isDismissed,
      dismiss,
      hasRightSlot,
      mdiClose
    }
  }
}
</script>
