<template>
  <modal-box
    v-model="isConfirmDeleteModalActive"
    :title="$t('general_confirm_action')"
    has-cancel
    :button-label="$t('general_delete')"
    button="danger"
    @confirm="userDelete()"
  >
    <notification v-if="errorMessage" color="danger" :icon="mdiAlertCircle" :outline="true" :hasDismissButton="false">
      <b>{{ $t('messages.error_title') }}:</b> {{ errorMessage }}
    </notification>

    <p>{{ $t('general_confirm_delete_account_of') }}: {{ deleteUser.email }}</p>
  </modal-box>

  <modal-box
    :isLoading="isEditingUser"
    v-model="modalEditUser"
    :title="`Wijzig gegevens van gebruiker ${editUserForm.email}`"
    button-label="Wijzig"
    button="azure"
    @cancel="editUserClose"
    @confirm="editUserSubmit"
    has-cancel
  >
    <notification v-if="successMessage" color="success" :icon="mdiAccountPlus" :outline="true" :hasDismissButton="false">
      {{ successMessage }}
    </notification>
    <notification v-if="errorMessage" color="danger" :icon="mdiAlertCircle" :outline="true" :hasDismissButton="false">
      <b>{{ $t('messages.error_title') }}:</b> {{ errorMessage }}
    </notification>
    
    <field :label="$t('general_company_name')">
      <control
        :placeholder="$t('general_company_name')"
        :icon-right="mdiAccount"
        v-model="editUserForm.company"
        name="company"
        />
    </field>

    <field :label="$t('general_firstname')">
      <control
        :placeholder="$t('general_firstname')"
        :icon-right="mdiAccount"
        v-model="editUserForm.firstname"
        name="firstname"
        />
    </field>

    <field :label="$t('general_lastname')">
      <control
        :placeholder="$t('general_lastname')"
        :icon-right="mdiAccount"
        v-model="editUserForm.lastname"
        name="lastname"
        />
    </field>

    <field :label="$t('general_password_title')">
      <control
        v-model="editUserForm.password"
        :placeholder="$t('general_password_title')"
        type="password"
        name="password"
        autocomplete="new-password"
        help="Alleen invullen bij het wijzigen van het wachtwoord"
        />
    </field>
    <field>
      <check-radio-picker
        name="is_superuser"
        v-model="editUserForm.is_superuser"
        :options="checkboxOptions"
        column
      />
    </field>
  </modal-box>

  {{ searchString }}
  <table>
    <thead>
    <tr>
      <th @click="greet" sort-by="lastname" class="text-sm underline">{{ $t('general_name') }}</th>
      <th @click="greet" sort-by="email" class="text-sm underline">{{ $t('general_email') }}</th>
      <th @click="greet" sort-by="last_login" class="text-sm underline">{{ $t('general_last_login') }}</th>
      <th class="text-sm">Tags</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="user in itemsPaginated" :key="user.id">
      <td data-label="Name">{{ user.lastname }}, {{ user.firstname }}</td>
      <td data-label="Email">{{ user.email }}</td>
      <td data-label="Last Login">
        <pill v-if="user.last_login" type="success" :text="user.last_login" small/>
      </td>
      <td data-label="Tags">
        <pill v-if="user.subscription" type="success" text="subscribed" small/>
        <pill v-if="user.is_active" type="success" text="active" small/>
        <pill v-if="user.is_superuser" type="danger" text="admin" small/>
      </td>
      <td class="actions-cell">
        <jb-buttons type="justify-end" no-wrap>
          <jb-button :icon="mdiHumanEdit" color="success" @click="editUser(user)" small />
          <jb-button :icon="mdiTrashCan" color="danger" @click="confirmDelete(user)" small />
        </jb-buttons>
      </td>
    </tr>
    </tbody>
  </table>
  <div class="table-pagination">
    <level mobile>
      <jb-buttons glue>
        <jb-button
          v-for="page in pagesList"
          @click="currentPage = page"
          :outline="darkMode"
          :active-soft="page === currentPage"
          :key="page"
          :label="page + 1"
          addon
          small
          no-focus-ring
        />
      </jb-buttons>
      <small>{{ $t('general_page') }} {{ currentPageHuman }} {{ $t('general_page_of') }} {{ numPages }}</small>
    </level>
  </div>
</template>

<script setup>
import CheckRadioPicker from '@/components/CheckRadioPicker'
import { useI18n } from 'vue-i18n'
import { ref, computed, reactive, watch, onMounted, defineProps } from 'vue'
import { useStore } from 'vuex'
import { mdiAccount, mdiAccountPlus, mdiAlertCircle, mdiEye, mdiTrashCan, mdiLifebuoy, mdiHumanEdit } from '@mdi/js'
import ModalBox from '@/components/ModalBox'
import Notification from '@/components/Notification'
import Field from '@/components/Field'
import Control from '@/components/Control'
import Level from '@/components/Level'
import Pill from '@/components/Pill'
import JbButtons from '@/components/JbButtons'
import JbButton from '@/components/JbButton'
import Repository from '@/repositories/RepositoryFactory'

const props = defineProps({
  model: {
    type: Object,
    default: () => ({})
  },
  searchString: {
    type: String,
    default: ''
  },
  perPage: {
    type: Number,
    default: 10
  }
})

const checkboxOptions = { is_superuser: 'Is administrator' }
const { t } = useI18n()
const store = useStore()
const AuthRepository = Repository.get('auth')
const darkMode = computed(() => store.state.display.darkMode)
const items = ref([])
const isConfirmDeleteModalActive = ref(false)
const currentPage = ref(0)
const deleteUser = ref({})
const successMessage = ref('')
const errorMessage = ref('')
const isEditingUser = ref(false)
const modalEditUser = ref(false)

const itemsPaginated = computed(() => items.value.slice(props.perPage * currentPage.value, props.perPage * (currentPage.value + 1)))
const numPages = computed(() => Math.ceil(items.value.length / props.perPage))
const currentPageHuman = computed(() => currentPage.value + 1)
const pagesList = computed(() => Array.from({ length: numPages.value }, (_, i) => i))

const editUserForm = reactive({
  email: '',
  firstname: '',
  lastname: '',
  company: '',
  password: '',
  is_superuser: false
})

const confirmDelete = (user) => { 
  deleteUser.value = user
  isConfirmDeleteModalActive.value = true
}

const editUser = (user) => { 
  modalEditUser.value = true
  editUserForm.email = user.email
  editUserForm.firstname = user.firstname
  editUserForm.lastname = user.lastname
  editUserForm.company = user.company
  editUserForm.is_superuser = user.is_superuser
}

const editUserClose = () => {
  modalEditUser.value = false
  editUserForm.email = ''
  editUserForm.firstname = ''
  editUserForm.lastname = ''
  editUserForm.company = ''
  editUserForm.password = ''
  editUserForm.is_superuser = false
}

const editUserSubmit = async () => {
  isEditingUser.value = true
  successMessage.value = ''
  errorMessage.value = ''

  try {
    await AuthRepository.updateUser(editUserForm).then(response => {
      successMessage.value = t('messages.account_updated')
      store.dispatch('display/pushMessage', successMessage)

      setTimeout(() => {
        modalEditUser.value = false
      }, 750)

      getUsersAdministration()
    })
  } catch (error) {
    if (error.response !== undefined && 'data' in error.response && 'detail' in error.response.data) {
      errorMessage.value = error.response.data.detail
    }
  } finally {
    isEditingUser.value = false
  }
}

const getUsersAdministration = () => {
  AuthRepository.getUsersAdministration({ search: props.searchString }).then(response => {
    items.value = response.data.users
  }).catch(error => {
    errorMessage.value = t(error.response.data.detail)
  })
}

const userDelete = () => { 
  AuthRepository.deleteUser({ email: deleteUser.value.email }).then(response => {
    successMessage.value = t('messages.account_deleted')
    store.dispatch('display/pushMessage', successMessage)

    setTimeout(() => {
      deleteUser.value = {}
    }, 750)

    getUsersAdministration()
    isConfirmDeleteModalActive.value = false
    errorMessage.value = ''
  }).catch(error => {
    errorMessage.value = t(error.response.data.detail)
  })
}

// watch(() => props.searchString, getUsersAdministration, { immediate: true })

onMounted(getUsersAdministration)
</script>
