<template>
  <section :class="sectionClass">
    <slot/>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const isFormScreen = computed(() => store.state.display.isFormScreen)
const isMobileMenuVisible = computed(() => store.state.display.isMobileMenuVisible)

const sectionClass = computed(() => {
  if (!isFormScreen.value && isMobileMenuVisible.value) {
    return 'py-6 pt-6 px-0 md:px-6'
  } else if (!isFormScreen.value) {
    return 'py-6 pt-24 md:pt-32 px-0 md:px-6'
  }
  return 'px-0 md:px-6'
})
</script>
