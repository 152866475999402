export default {
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "choose_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a day"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test"])},
  "register_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Account"])},
  "language_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN"])},
  "language_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "language_dutch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch"])},
  "language_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "general_welcome_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome Back!"])},
  "general_latest_tweets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Tweets"])},
  "general_tweets_view_on_twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View on Twitter"])},
  "general_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
  "general_source_various": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source: Various"])},
  "general_click_to_show_or_hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to Show or Hide"])},
  "general_current_password_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "general_current_new_password_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
  "general_current_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "general_current_new_password_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
  "general_password_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "general_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password"])},
  "general_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail Address"])},
  "general_email_help_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your E-mail Address"])},
  "general_firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname"])},
  "general_lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastname"])},
  "general_button_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "general_button_ask_for_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need Help?"])},
  "general_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
  "general_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question"])},
  "general_question_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your question"])},
  "general_question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can we help you?"])},
  "general_captcha_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in the characters below"])},
  "general_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "general_name_help_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Name"])},
  "general_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "general_message_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message Sent"])},
  "general_tagline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecting Energy and Data"])},
  "general_receive_newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive Newsletter"])},
  "general_read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read More"])},
  "general_read_more_about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Profiteia"])},
  "general_button_signup_newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
  "general_reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
  "general_resend_activation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend Activation"])},
  "general_back_to_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back To Login"])},
  "general_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "general_updated_automatically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated automatically"])},
  "general_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
  "general_button_contact_us_with_questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions? Contact Us"])},
  "menu_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriptions"])},
  "menu_dashboards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboards"])},
  "menu_general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
  "menu_energy_outlook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy Outlook"])},
  "menu_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "menu_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "menu_batteries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Battery Outlook"])},
  "menu_battery_simulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Battery Simulation"])},
  "menu_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "menu_display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display"])},
  "menu_about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
  "menu_darkmode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark/Light"])},
  "menu_gas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas"])},
  "menu_carbon_dioxide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO2"])},
  "menu_oil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oil"])},
  "menu_electricity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electricity"])},
  "menu_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "menu_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "menu_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "menu_logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "menu_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "menu_subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])},
  "menu_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Members"])},
  "menu_pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing"])},
  "menu_subscribers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribers"])},
  "card_title_ice_eua_futures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICE EUA Futures"])},
  "card_title_entsoe_day_ahead_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENTSO-E Day Ahead Prices"])},
  "row_titles": {
    "mains_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mains Frequency"])},
    "gas_storage_and_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas Storage and Use"])},
    "oil_gas_and_co2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oil, Gas & CO2"])},
    "eu_allowance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EU Allowance"])},
    "gas_and_power": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas & Power"])},
    "gas_and_power_nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch Gas & Power Prices"])},
    "power_nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electricity Base Load Futures Netherlands"])},
    "coal_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coal Prices"])},
    "oil_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oil Prices"])},
    "gas_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas Prices"])},
    "ttf_gas_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTF Gas Prices"])},
    "european_energy_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electricity Base Load Futures Europe"])},
    "dutch_day_ahead_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch Day Ahead Prices"])},
    "european_day_ahead_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day Ahead Prices Europe"])},
    "spark30s_atlantic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spark30S Atlantic Sabine Pass to Gate LNG Freight Future"])},
    "spark25s_pacific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spark25S Pacific NWS to Tianjin LNG Freight Future"])}
  },
  "card_titles": {
    "ttf_versus_jkm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTF versus JKM"])},
    "world_gas_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["World Gas Prices"])},
    "temperature_and_wind_nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wind and temperature (NL)"])},
    "solar_impact_nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solar impact per hour (NL)"])},
    "price_ladder_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price Ladder Today"])},
    "price_ladder_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price Ladder Last Week"])},
    "wind_speed_nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wind Speed (m/s) (NL)"])},
    "day_ahead_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day Ahead Prices"])},
    "wind_and_solar_forecast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wind And Solar Forecast"])},
    "electricy_forecast_and_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forecast and load combined"])},
    "electricy_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electricity Usage"])},
    "electricy_generation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electricity Generation"])},
    "forecasted_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forecasted Load"])},
    "generation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generation"])},
    "balance_delta_and_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance Delta and Prices"])},
    "imbalance_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imbalance Prices"])},
    "capacity_price_ladder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacity Price Ladder"])},
    "capacity_price_ladder_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacity Price Ladder Today"])},
    "capacity_price_ladder_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacity Price Ladder Last Week"])},
    "spread_settlement_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spread Settlement Prices"])},
    "spreads_combined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spreads Combined"])},
    "intraday_spread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intraday Spread"])},
    "day_ahead_spread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day Ahead Spread"])},
    "sum_of_upwards_and_downwards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum of dispatch"])},
    "activated_balancing_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activated Balancing Prices"])},
    "afrr_netherlands_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aFRR Netherlands Months"])},
    "afrr_netherlands_per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aFRR Netherlands Years"])},
    "mfrr_netherlands_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mFRR Netherlands Months"])},
    "mfrr_netherlands_per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mFRR Netherlands Years"])},
    "mfrr_netherlands_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mFRR Netherlands Last Week"])},
    "afrr_netherlands_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aFRR Netherlands Last Week"])},
    "fcr_netherlands_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FCR Netherlands Months"])},
    "fcr_netherlands_per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FCR Netherlands Years"])},
    "fcr_netherlands_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FCR Netherlands Today"])},
    "fcr_netherlands_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FCR Netherlands Last Week"])},
    "fcr_netherlands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FCR Netherlands"])},
    "mains_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mains Frequency"])},
    "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Profiteia"])},
    "multi_gas_price_world": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["World Gas Prices"])},
    "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
    "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Profile"])},
    "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "agsi_gas_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas Storage Statistics"])},
    "alsi_gas_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LNG Storage Statistics"])},
    "multi_gas_prices_map_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["World Wide Gas Prices (€/MWh)"])},
    "gie_gas_storage_map_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas Storage Percentages"])},
    "dutch_ttf_gas_base_load_futures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch TTF Gas Base Load Futures"])},
    "dutch_ttf_gas_base_load_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch TTF Gas Base Load Forward"])},
    "dutch_ttf_gas_base_load_settlements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch TTF Gas Base Load Settlements"])},
    "dutch_ttf_live_frontmonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch TTF Gas Front Month"])},
    "dutch_ttf_live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch TTF Gas"])},
    "dutch_ttf_gas_base_load_front_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch TTF Gas Front Month"])},
    "brent_crude_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brent Crude Forward"])},
    "brent_crude_settlements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brent Crude Settlements"])},
    "combined_settlements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settlements (Combined)"])},
    "rotterdam_coal_futures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotterdam Coal Futures"])},
    "rotterdam_coal_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotterdam Coal Forward"])},
    "rotterdam_coal_front_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotterdam Coal Front Month"])},
    "rotterdam_coal_frontmonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotterdam Coal Front Month"])},
    "rotterdam_coal_settlements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotterdam Coal Settlements"])},
    "ice_eua_futures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUA Futures"])},
    "ice_eua_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUA Forward"])},
    "ice_eua_settlements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUA Settlements"])},
    "ice_eua_front_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUA Front Month"])},
    "dutch_power_base_load_futures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch Power Base Load Futures"])},
    "dutch_power_peak_load_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch Power Peak Futures"])},
    "dutch_power_off_peak_load_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch Power Off-Peak Futures"])},
    "dutch_power_base_load_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch Power Base Load Forward"])},
    "dutch_power_base_load_settlements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch Power Base Load Settlements"])},
    "italian_power_base_load_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch Power Base Load Futures"])},
    "german_power_base_load_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian Power Base Load Futures"])},
    "french_power_base_load_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French Power Base Load Futures"])},
    "dutch_day_ahead_prices_moving_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EPEX-Spot DAM"])},
    "dutch_day_ahead_prices_week_heatmap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seven days DA Prices"])},
    "tennet_settlementprices_seven_week_heatmap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seven weeks Imbalance Prices"])},
    "tennet_settlementprices_imbalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TenneT Imbalance Prices MA"])},
    "tennet_imbalance_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TenneT Imbalance Prices"])},
    "dutch_day_ahead_prices_yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch DA Prices Yesterday"])},
    "dutch_day_ahead_prices_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch DA Prices Today"])},
    "dutch_day_ahead_prices_tomorrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch DA Prices Tomorrow"])},
    "dutch_day_ahead_prices_last_two_weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two weeks NL DA"])},
    "german_day_ahead_prices_last_two_weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two weeks DE DA"])},
    "spark25s_pacific_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pacific NWS to Tianjin LNG Freight Forward"])},
    "spark25s_pacific_settlements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pacific NWS to Tianjin LNG Freight Settlements"])},
    "spark30s_atlantic_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atlantic Sabine Pass to Gate LNG Freight Forward"])},
    "spark30s_atlantic_settlements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atlantic Sabine Pass to Gate LNG Freight Settlements"])},
    "entsog_month_and_year_average_netherlands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas usage Netherlands"])},
    "tennet_settlementprices_take": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imbalance prices for shortage"])},
    "entsoe_contracted_reserves_sums": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contracted volumes balancing"])},
    "tennet_settlementprices_monthly_sums": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realised settlement prices"])},
    "entsoe_reserved_balancing_reserves_sums": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserved balancing reserves"])},
    "entsoe_activated_balancing_prices_sums": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activated balancing prices"])},
    "gie_gas_storage_in_netherlands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas storage in the Netherlands"])}
  },
  "newsletter_introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in your details below to receive our newsletter. You can unsubscribe from our newsletter at any time."])},
  "newsletter_teaser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay up to date with our market information and platform updates"])},
  "newsletter_call_to_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive Newsletter and Updates"])},
  "information_card_subscribers_welcome_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribers Only"])},
  "information_card_subscribers_welcome_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This section of our website is only for Profiteia.io subscribers!"])},
  "information_card_welcome_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome To"])},
  "information_card_welcome_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiteia.io provides insights on (developments in) the European energy market. With a focus on renewable energy and energy efficiency measures we hope to strengthen the energy transition. Currently the website is in active development and only some parts are available (to the public).    "])},
  "information_card_sign_up_for_our_newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up for Our Newsletter"])},
  "information_card_interested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interested"])},
  "register_already_have_an_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already Have an Account?"])},
  "register_forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password?"])},
  "resend_activation_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend Activation Link"])},
  "messages": {
    "account_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This functionality is only for registered users."])},
    "subscription_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This functionality is only for registered users with an active subscription."])},
    "invalid_refresh_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Expired"])},
    "invalid_access_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Expired"])},
    "session_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Expired"])},
    "password_changed_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Changed. Please login again."])},
    "contact_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us to active your accountResendActivationCode"])},
    "page_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Not Found"])},
    "page_not_found_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check your URL or contact us!"])},
    "requires_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This page requires an account on Profiteia"])},
    "account_not_yet_activated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account is not activated. Check your e-mail."])},
    "update_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Available!"])},
    "click_here_to_apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please click here to apply"])},
    "update_available_click_here_to_apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update available! Click to apply"])},
    "invalid_captcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CAPTCHA"])},
    "error_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "check_your_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Your E-mail"])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your e-mail to confirm your account"])},
    "check_your_email_to_reactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your e-mail to confirm your account"])},
    "registration_confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your e-mail to confirm your account"])},
    "login_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login successful, redirecting to home"])},
    "enter_email_and_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter E-mail Address and Password"])},
    "invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid E-mail Address"])},
    "invalid_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid password"])},
    "passwords_do_not_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New passwords do not match"])},
    "newsletter_subscription_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are now subscribed to our newsletter"])},
    "incorrect_email_or_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid E-mail or Password"])},
    "error_loading_tweets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error loading Tweets"])}
  },
  "chart_labels": {
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min"])},
    "avg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average"])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max"])},
    "hourly_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hourly Price"])},
    "front_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Front Month"])},
    "front_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Front Year"])},
    "day_ahead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day Ahead"])},
    "temperature_nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature (NL)"])},
    "temperature_and_wind_nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wind and temperature (NL)"])},
    "solar_impact_nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solar impact per hour (NL)"])},
    "price_ladder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price Ladder"])},
    "price_ladder_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price Ladder Today"])},
    "datetime_trading_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading Day"])},
    "settlement_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settlement Price"])},
    "day_ahead_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day Ahead Price"])},
    "wind_offshore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wind Offshore"])},
    "wind_onshore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wind Onshore"])},
    "solar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solar"])},
    "actual_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actual Load"])},
    "forecasted_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forecasted Load"])},
    "actual_generation_aggregated_sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actual Generation"])},
    "forecasted_generation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forecasted Generation"])},
    "balance_delta_in_mw_imbalance_per_mwh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance Delta in MW, Imbalance per MWh"])},
    "downward_dispatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downward Dispatch"])},
    "upward_dispatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upward Dispatch"])},
    "total_downward_capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total downward capacity"])},
    "total_upward_capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total upward capacity"])},
    "spread_day_ahead_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spread DA"])},
    "spread_intraday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spread Intraday"])},
    "spread_settlement_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spread Settlement Price"])},
    "storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage"])},
    "upwards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upwards"])},
    "downwards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downwards"])},
    "reserved_fcr_symmetric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FCR Symmetric"])},
    "reserved_afrr_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aFRR Up"])},
    "reserved_afrr_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aFRR Down"])},
    "avg_reserved_fcr_symmetric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average FCR Symmetric"])},
    "sum_reserved_fcr_symmetric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum FCR Symmetric"])},
    "sum_reserved_afrr_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum aFRR Up"])},
    "sum_reserved_afrr_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum aFRR Down"])},
    "average_capacity_in_mw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average capacity in MW"])},
    "capacity_in_mw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacity in MW"])},
    "sum_afrr_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum aFRR Up"])},
    "sum_afrr_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum aFRR Down"])},
    "sum_mfrr_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum aFRR Up"])},
    "sum_mfrr_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum aFRR Down"])},
    "sum_reserved_mfrr_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum mFRR Up"])},
    "sum_reserved_mfrr_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum mFRR Down"])},
    "datetime_in_quarter_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time in quarter hours"])},
    "datetime_in_four_hour_blocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocks of four hours"])},
    "datetime_in_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time in days"])},
    "datetime_in_months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time in months"])},
    "datetime_in_years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time in years"])},
    "datetime_in_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time in hours"])},
    "capacity_mw_isp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacity per MW per ISP"])},
    "capacity_mw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacity per MW"])},
    "activated_balancing_prices_mwh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices activated MWh"])},
    "total_mwh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total MWh"])},
    "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "max_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max Price"])},
    "min_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min Price"])},
    "max_pos_300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max Pos 300"])},
    "max_pos_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max Pos 100"])},
    "max_pos_600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max Pos 600"])},
    "min_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min 100"])},
    "min_300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min 300"])},
    "min_600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min 600"])},
    "frequency_mhz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequency in Hz"])},
    "charter_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$/charter day"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uur"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
    "pte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PTE"])},
    "end_of_day_settlement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Of Day Settlement"])},
    "euro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR"])},
    "dollar_per_tonne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$/tonne"])},
    "dollar_per_barrel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$/BBL"])},
    "euro_per_mwh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€/MWh"])},
    "euro_per_tonne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€/tonne"])},
    "twh_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TWh/month"])},
    "twh_per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TWh/year"])},
    "mwh_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MWh/month"])},
    "mwh_per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MWh/year"])},
    "fn_min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum"])},
    "fn_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum"])},
    "fn_avg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average"])},
    "ma_take_from_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moving Average Take From"])},
    "ma_feed_into_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moving Average Feed Into"])},
    "take_from_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take from system"])},
    "feed_into_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feed into system"])},
    "activated_afrr_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activated aFRR Up"])},
    "activated_afrr_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activated aFRR Down"])},
    "activated_mfrr_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activated mFRR Up"])},
    "activated_mfrr_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activated mFRR Down"])},
    "afrr_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aFRR Down"])},
    "afrr_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aFRR Up"])},
    "mfrr_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mFRR Down"])},
    "mfrr_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mFRR Up"])},
    "fcr_symmetric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FCR Symmetric"])},
    "stacked_by_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stacked By Year"])},
    "cumulative_current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cumulative current"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current"])},
    "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count"])}
  },
  "page_titles": {
    "menu_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnementen"])},
    "battery_simulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Battery Simulation"])},
    "batteries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batteries Dashboard"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrative"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "dashboard_ns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard NS"])},
    "dashboard_por": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard Havenbedrijf Rotterdam"])},
    "energy_outlook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energierapportage"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foutmelding"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiel"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreer"])},
    "activate_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activateer e-mail"])},
    "resend_activation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herstuur activatie"])},
    "recover_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset E-mail"])},
    "return_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnementen"])},
    "recover_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset wachtwoord"])},
    "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijzen"])},
    "page_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina niet gevonden"])}
  },
  "gasInStorage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas Storage"])},
  "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netherlands"])},
  "january": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January"])},
  "february": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February"])},
  "march": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
  "april": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
  "may": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
  "june": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])},
  "july": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])},
  "august": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
  "september": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
  "october": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October"])},
  "november": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
  "december": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])},
  "jan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jan"])},
  "feb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feb"])},
  "mar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mar"])},
  "apr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apr"])},
  "jun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jun"])},
  "jul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jul"])},
  "aug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aug"])},
  "sep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sep"])},
  "oct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oct"])},
  "nov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nov"])},
  "dec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dec"])},
  "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])},
  "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
  "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
  "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])},
  "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
  "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
  "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])},
  "decimalPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([","])},
  "thousandsSep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["."])},
  "subscriptions": {
    "confirm_subscription_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Subscription Deletion"])},
    "confirm_subscription_delete_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action will immediately delete your subscription, and this action is irreversible."])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
    "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "reference_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference ID"])},
    "period_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period start"])},
    "period_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period end"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
    "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices"])},
    "current_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Subscription"])},
    "current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Plan"])},
    "our_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Subscriptions"])},
    "choose_your_flavor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your flavour"])},
    "beginner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginner"])},
    "essentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essentials"])},
    "good_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good start"])},
    "access_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access to all"])},
    "dashboards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dashboards"])},
    "receive_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive monthly"])},
    "analytics_newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["analytics newsletter"])},
    "standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
    "basic_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic options"])},
    "most_popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most popular"])},
    "all_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All previous"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["options"])},
    "all_previous_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All previous options"])},
    "custom_dashboards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom dashboards"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom"])},
    "first_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First to"])},
    "new_features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new features"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "and_discount_on_training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and discount on training"])},
    "access_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access to"])},
    "custom_analytics_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom analytics tool"])},
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["access"])},
    "custom_api": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom API"])},
    "sla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])},
    "support_guaranteed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["support guaranteed"])},
    "all_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All options"])},
    "first_to_experience_new_features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First to experience new features"])},
    "support_and_discount_on_training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support and discount on training"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro"])},
    "big_data_analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Big data analysis"])},
    "access_to_custom_analytics_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access to Custom analytics tool"])},
    "custom_api_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom API access"])},
    "sla_support_guaranteed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA support guaranteed"])}
  },
  "homepage_highlights": {
    "energy_outlook_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View our Energy Outlook"])},
    "energy_outlook_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our energy report provides a quick insight into the current developments in various energy markets."])},
    "account_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An account is required to view the energy report."])},
    "energy_outlook_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy Outlook"])},
    "customized_solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customized Solutions"])},
    "customized_solutions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are experienced in processing and transforming large amounts of data. And with translating data into knowledge. We would like to think along with this. About how we can offer our data, but also about how own company data can be presented."])},
    "customized_solutions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "register_account_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration"])},
    "register_account_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay informed about our developments by following our newsletter. Or register an account to view our energy reports. We are working hard on various subscription models and new functionality."])},
    "register_account_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register your account now"])}
  }
}