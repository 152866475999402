<template>
  <transition
    enter-active-class="transition duration-100 ease-in"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition duration-100 ease-in"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <overlay v-show="value || isLoading" @overlay-click="cancel">
      <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="animate-fadeOut"
      >
        <card-component
          v-show="value"
          :title="title"
          class="shadow-lg w-full max-h-modal md:w-3/5 lg:w-2/5 z-50"
          :class="{ 'animate-shake': shake }"
          :header-icon="mdiClose"
          @header-icon-click="cancel"
        >
          <div class="space-y-3">
            <h1 v-if="largeTitle" class="text-2xl">{{ largeTitle }}</h1>
            <slot />
          </div>

          <template #footer>
            <level mobile>
              <jb-buttons>
                <jb-button :label="buttonLabel" :color="button" @click="confirm" />
                <jb-button v-if="hasCancel" :label="$t('general_button_cancel')" @click="cancel" :color="button" outline />
              </jb-buttons>

              <text-link :to="textLinkTo" :label="textLinkLabel" :icon="textLinkIcon" @click="cancel"/>
            </level>
          </template>
        </card-component>
      </transition>
    </overlay>
  </transition>
</template>

<script>
import { computed } from 'vue'
import { mdiClose } from '@mdi/js'
import Overlay from '@/components/Overlay'
import CardComponent from '@/components/CardComponent'
import JbButtons from '@/components/JbButtons'
import JbButton from '@/components/JbButton'
import Level from '@/components/Level'
import TextLink from '@/components/TextLink'

export default {
  name: 'ModalBox',
  components: {
    Overlay,
    CardComponent,
    JbButton,
    JbButtons,
    Level,
    TextLink
  },
  props: {
    title: String,
    largeTitle: String,
    button: {
      type: String,
      default: 'info'
    },
    buttonLabel: {
      type: String,
      default: 'Done'
    },
    shake: Boolean,
    hasCancel: Boolean,
    textLinkLabel: String,
    textLinkTo: String,
    textLinkIcon: String,
    modelValue: [String, Number, Boolean],
    isLoading: { 
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'cancel', 'confirm'],
  setup (props, { emit }) {
    const value = computed({
      get: () => props.modelValue,
      set: value => emit('update:modelValue', value)
    })

    const confirm = () => {
      emit('confirm')
    }

    const cancel = () => {
      value.value = false 
      emit('cancel')
    }

    return {
      value,
      confirm,
      cancel,
      mdiClose
    }
  }
}
</script>
