<template>
  <main-section v-if="isSuperUser">
    <jb-button label="Voeg gebruiker toe" color="azure" @click="modalNewUser = true" class="mb-5" :icon="mdiAccountPlus"/>

    <modal-box
      :isLoading="isLoadingNewUser"
      v-model="modalNewUser"
      title="Voeg gebruiker toe"
      button-label="Registreer"
      button="azure"
      @cancel="newUserClose"
      @confirm="newUserSubmit"
      has-cancel
    >
      <notification v-if="successMessage" color="success" :icon="mdiAccountPlus" :outline="true" :hasDismissButton="false">
        {{ successMessage }}
      </notification>
      <notification v-if="errorMessage" color="danger" :icon="mdiAlertCircle" :outline="true" :hasDismissButton="false">
        <b>{{ $t('messages.error_title') }}:</b> {{ errorMessage }}
      </notification>
      
      <field :label="$t('general_company_name')">
        <control
          :placeholder="$t('general_company_name')"
          :icon-right="mdiAccount"
          v-model="newUserForm.company"
          name="company"
          id="company"
          :required="true"
          />
      </field>

      <field :label="$t('general_firstname')">
        <control
          :placeholder="$t('general_firstname')"
          :icon-right="mdiAccount"
          v-model="newUserForm.firstname"
          name="firstname"
          id="firstname"
          :required="true"
          />
      </field>

      <field :label="$t('general_lastname')">
        <control
          :placeholder="$t('general_lastname')"
          :icon-right="mdiAccount"
          v-model="newUserForm.lastname"
          name="lastname"
          id="lastname"
          :required="true"
          />
      </field>

      <field :label="$t('general_email')">
        <control
          :placeholder="$t('general_email')"
          :icon-right="mdiEmail"
          v-model="newUserForm.email"
          name="login"
          type="email"
          id="email"          
          autocomplete="E-mail"
          :required="true"
          />
      </field>

      <field :label="$t('general_password_title')">
        <control
          v-model="newUserForm.password"
          :placeholder="$t('general_password_title')"
          type="password"
          name="password"
          id="password"
          autocomplete="new-password"
          :required="true"
          />
      </field>

    </modal-box>

    <div class="grid grid-cols-1 gap-6 mb-6 xl:grid-cols-1">
      <card-component title="Gebruikers" :icon="mdiAccountMultiple" class="mb-6 lg:mb-0 lg:col-span-2 xl:col-span-3 p-2" has-table>
        <clients-table :searchString="searchString" ref="clientsTableRef"/>
      </card-component>
    </div>
  </main-section>
</template>

<script setup>
import { watch, onMounted, computed, ref, reactive } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import { mdiEmail, mdiAccountPlus, mdiAccount, mdiAccountMultiple, mdiAlertCircle } from '@mdi/js'
import MainSection from '@/components/MainSection'
import Notification from '@/components/Notification'
import ClientsTable from '@/components/ClientsTable'
import CardComponent from '@/components/CardComponent'
import ModalBox from '@/components/ModalBox'
import JbButton from '@/components/JbButton'
import Field from '@/components/Field'
import Control from '@/components/Control'
import Repository from '@/repositories/RepositoryFactory'

const { t } = useI18n()
const store = useStore()
const AuthRepository = Repository.get('auth')
const isAuthenticated = computed(() => store.state.auth.isAuthenticated)
const isSuperUser = computed(() => store.state.auth.isSuperUser)
const isFetchingUser = computed(() => store.state.auth.isFetchingUser)

const modalNewUser = ref(false)
const isLoadingNewUser = ref(false)
const errorMessage = ref('')
const successMessage = ref('')
const searchString = ref('')
const clientsTableRef = ref(null)

const newUserForm = reactive({
  email: '',
  firstname: '',
  lastname: '',
  company: '',
  password: ''
})

const newUserClose = () => {
  modalNewUser.value = false
}

const newUserSubmit = async () => {
  isLoadingNewUser.value = true
  successMessage.value = ''
  errorMessage.value = ''
  try {
    await AuthRepository.register(newUserForm).then(response => {
      successMessage.value = t('messages.account_added')
      store.dispatch('display/pushMessage', successMessage)

      setTimeout(() => {
        modalNewUser.value = false

        // Clear newUserForm fields
        for (const key in newUserForm) {
          newUserForm[key] = ''
        }
      }, 750)

      clientsTableRef.value.getUsersAdministration()
    })
  } catch (error) {
    if (error.response !== undefined && 'data' in error.response && 'detail' in error.response.data) {
      errorMessage.value = error.response.data.detail
    }
  } finally {
    isLoadingNewUser.value = false
  }
}

watch([isFetchingUser], () => {
  console.log('was fetching user')
})

onMounted(() => {
  
})
</script>
