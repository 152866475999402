import i18n from '@/i18n'
import {
  mdiFinance,
  mdiAccount,
  mdiChartAreaspline,
  mdiEmailOpen,
  mdiCog,
  mdiCrown,
  mdiLogin,
  mdiLogout
} from '@mdi/js'

export default [
  [
    {
      first: true,
      to: '/account/login',
      auth: false,
      label: i18n.global.t('menu_login'),
      icon: mdiLogin
    },
    {
      first: true,
      to: '/account/profile',
      auth: true,
      label: i18n.global.t('menu_profile'),
      icon: mdiAccount
    },
    {
      to: '/',
      label: i18n.global.t('menu_dashboard'),
      icon: mdiChartAreaspline
    },
    {
      to: '/account/bidding',
      auth: true,
      label: i18n.global.t('menu_bidding_strategy'),
      icon: mdiFinance
    },
    {
      to: '/contact',
      label: i18n.global.t('menu_contact'),
      icon: mdiEmailOpen
    },
    {
      to: '/admin',
      label: i18n.global.t('menu_admin'),
      icon: mdiCrown,
      auth: true,
      admin: true
    },
    {
      label: i18n.global.t('menu_logout'),
      icon: mdiLogout,
      auth: true,
      action: 'doLogout'
    }
  ]
]
