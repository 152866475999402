import AuthRepository from './AuthRepository'
import EmailRepository from './EmailRepository'
import StatusRepository from './StatusRepository'
import GraphQLRepository from './GraphQLRepository'
import PrivateRepository from './PrivateRepository'
import ExternalRepository from './ExternalRepository'
import StatisticsRepository from './StatisticsRepository'

const repositories = {
  auth: AuthRepository,
  email: EmailRepository,
  status: StatusRepository,
  graphql: GraphQLRepository,
  private: PrivateRepository,
  external: ExternalRepository,
  statistics: StatisticsRepository
}

export default {
  get: name => repositories[name]
}
