<template>
  <div>
    <nav-bar/>
    <text-link v-if="updateExists" @click="refreshApp" :label="$t('messages.update_available_click_here_to_apply')" :icon="mdiUpdate" class="text-xs md:text-sm lg:text-base cursor-pointer text-blue-600 underline mx-5 my-5"/>
    <router-view/>
    <overlay v-show="isOverlayVisible" z-index="z-40" @overlay-click="overlayClick" />
    <snackbar/>
  </div>
</template>

<script setup>
import { computed, ref, onBeforeUnmount, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { mdiUpdate } from '@mdi/js'
import { useRoute, useRouter } from 'vue-router'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import NavBar from '@/components/NavBar'
import Overlay from '@/components/Overlay'
import Snackbar from '@/components/Snackbar'
import TextLink from '@/components/TextLink'
const { t } = useI18n()
const store = useStore()
const route = useRoute()
const router = useRouter()

const updateExists = ref(false)
const registration = ref(false)

const isFormScreen = computed(() => store.state.display.isFormScreen)
const isAuthenticated = computed(() => store.state.auth.isAuthenticated)

const isAsideVisible = computed(() => store.state.display.isAsideVisible)
const isAsideHiddenLayout = computed(() => store.state.display.isAsideHiddenLayout)
const isOverlayVisible = computed(() => secondaryMenuItem.value || (isAsideHiddenLayout.value && isAsideVisible.value))

const secondaryMenuItem = computed({
  get: () => store.state.display.secondaryMenu,
  set: value => store.dispatch('display/secondaryMenuToggle', value)
})

const secondaryMenuClose = () => {
  secondaryMenuItem.value = null
}

const refreshApp = () => {
  updateExists.value = false
  if (!registration.value || !registration.value.waiting) return
  registration.value.waiting.postMessage({ type: 'SKIP_WAITING' })
  window.location.reload()
}

const updateAvailable = (event) => {
  registration.value = event.detail
  updateExists.value = false
}

const menuClose = () => {
  store.dispatch('display/asideVisibilityToggle', false)
  secondaryMenuClose()
}

const overlayClick = () => {
  if (secondaryMenuItem.value) {
    secondaryMenuClose()
  } else if (isAsideHiddenLayout.value && isAsideVisible.value) {
    menuClose()
  }
}

onBeforeUnmount(() => {
  document.removeEventListener('swUpdated', updateAvailable)
})

onMounted(async () => {
  document.addEventListener('swUpdated', updateAvailable)
  const fp = await FingerprintJS.load()
  const { visitorId } = await fp.get()
  store.commit('auth/setVisitorId', visitorId)
})

watch(route, (to, from) => {
  store.commit('auth/setRouteChange', {
    to: to.path,
    from: from.path
  })
  store.dispatch('auth/storeRouteChange')
  store.dispatch('auth/getUserInformation').then(response => {
    console.log(response)
    if (!isAuthenticated.value && !isFormScreen.value && route.path !== '/contact') {
      store.dispatch('display/pushMessage', t('messages.requires_account'))
      router.push('/account/login')
    }
  })
}, { deep: false })

</script>
