<template>
  <div :class="upperWrapperClass">
    <div
      class="relative"
      :class="wrapperClass"
    >
      <div v-if="computedType === 'static'" :class="inputElClass" class="inline-flex items-center whitespace-nowrap">
        {{ modelValue }}
      </div>
      <list-box-ui
        v-else-if="computedType === 'list'"
        v-model="computedValue"
        :options="options"
        :button-class="inputElClass"
      />
      <select
        v-else-if="computedType === 'select'"
        v-model="computedValue"
        :name="name"
        :id="id"
        :class="inputElClass">
        <option v-for="option in options" :key="option.id ?? option" :value="option">{{ option.label ?? option }}</option>
      </select>
      <textarea
        v-else-if="computedType === 'textarea'"
        v-model="computedValue"
        :class="inputElClass"
        :name="name"
        :id="id"
        :placeholder="placeholder"
        :required="required"
      ></textarea>
      <button
        v-else-if="computedType === 'button'"
        :class="inputElClass"
        class="ring-blue-700"
      >
        <icon v-if="buttonIcon" :path="buttonIcon" w="w-8" h="h-8" size="20" />
        <span v-if="buttonLabel" :class="{'ml-1':buttonIcon}">{{ buttonLabel }}</span>
      </button>
      <input
        v-else
        v-model="computedValue"
        :name="name"
        :autocomplete="autocomplete"
        :required="required"
        :disabled="disabled"
        :id="id"
        :placeholder="placeholder"
        :type="computedType"
        :class="inputElClass"
      >
      <control-icon
        v-if="computedIconLeft"
        :icon="computedIconLeft"
        :h="controlIconH"
        :text-color="textColor"
      />
      <control-icon
        v-if="computedIconRight"
        :icon="computedIconRight"
        :h="controlIconH"
        :text-color="textColor"
        :clickable="rightIconClickable"
        @icon-click="openPasswordToggle"
        is-right
      />
      <tip v-if="tipLeft" :tip="tipLeft" left />
      <tip v-if="tipRight" :tip="tipRight" right />
    </div>
    <field-help
      :help="help"
      :error="error"
      :success="success"
      class="mt-1"/>
  </div>
</template>

<script>
import { controlTextColor, colorsButtons, colorsButtonsOutline } from '@/colors.js'
import { computed, ref } from 'vue'
import {
  mdiUnfoldMoreHorizontal,
  mdiAlertCircle,
  mdiCheckCircle,
  mdiAsterisk,
  mdiLockOff
} from '@mdi/js'
import ControlIcon from '@/components/ControlIcon'
import FieldHelp from '@/components/FieldHelp'
import ListBoxUi from '@/components/ListBoxUi'
import Icon from '@/components/Icon'
import Tip from '@/components/Tip'

export default {
  name: 'Control',
  components: {
    ControlIcon,
    FieldHelp,
    ListBoxUi,
    Icon,
    Tip
  },
  props: {
    firstAddon: Boolean,
    middleAddon: Boolean,
    lastAddon: Boolean,
    expanded: Boolean,
    inputW: {
      type: String,
      default: 'w-full'
    },
    help: String,
    name: String,
    id: String,
    required: Boolean,
    disabled: Boolean,
    autocomplete: String,
    placeholder: String,
    iconLeft: String,
    iconRight: String,
    error: [Boolean, String],
    success: [Boolean, String],
    // tagInput: Boolean,
    options: Array,
    type: {
      type: String,
      default: 'text'
    },
    modelValue: {
      type: [String, Number, Boolean, Array, Object],
      default: ''
    },
    buttonLabel: String,
    buttonIcon: String,
    buttonColor: {
      type: String,
      default: 'white'
    },
    buttonOutline: String,
    borderless: Boolean,
    tipLeft: String,
    tipRight: String
  },
  emits: ['update:modelValue', 'right-icon-click'],
  setup (props, { emit }) {
    const computedValue = computed({
      get: () => props.modelValue,
      set: value => {
        emit('update:modelValue', value)
      }
    })

    const borderColor = computed(() => {
      if (props.error) {
        return 'border-red-600'
      }

      if (props.success) {
        return 'border-green-600'
      }

      return 'border-gray-700'
    })

    const textColor = computed(() => {
      return controlTextColor(props.error, props.success)
    })

    const placeholderColor = computed(() => {
      if (props.error) {
        return 'placeholder-red-600'
      }

      if (props.success) {
        return 'placeholder-green-600'
      }

      return null
    })

    const wrapperBorder = computed(() => ['textarea'].indexOf(computedType.value) < 0)

    const upperWrapperClass = computed(() => props.expanded ? 'flex-grow flex-shrink' : '')

    const wrapperClass = computed(() => {
      const base = []

      if (computedType.value === 'button') {
        base.push(
          props.buttonOutline ? colorsButtonsOutline[props.buttonColor] : colorsButtons[props.buttonColor]
        )
      } else {
        base.push(borderColor.value)

        if (wrapperBorder.value) {
          base.push('dark:bg-gray-800')
        }
      }

      if (!props.borderless && wrapperBorder.value) {
        base.push('border-t border-b')

        if (!props.firstAddon && !props.lastAddon && !props.middleAddon) {
          base.push('rounded border-l border-r')
        } else if (props.firstAddon) {
          base.push('rounded-l border-l')

          if (computedType.value !== 'button') {
            base.push('pr-1')
          }
        } else if (props.lastAddon) {
          base.push('rounded-r border-r')

          if (computedType.value !== 'button') {
            base.push('pl-1')
          }
        }
      }

      return base
    })

    const inputElClass = computed(() => {
      const base = [
        'px-3 py-2 max-w-full focus:ring focus:outline-none dark:placeholder-gray-400',
        props.inputW,
        computedType.value === 'textarea' ? 'h-24' : 'h-12',
        props.borderless || wrapperBorder.value ? 'border-0' : 'border'
      ]

      if (computedType.value === 'button') {
        base.push(
          props.buttonOutline ? colorsButtonsOutline[props.buttonColor] : colorsButtons[props.buttonColor]
        )
      } else {
        base.push(borderColor.value, 'dark:bg-gray-800')
      }

      if (textColor.value) {
        base.push(textColor.value)
      }

      if (placeholderColor.value) {
        base.push(placeholderColor.value)
      }

      if (!props.firstAddon && !props.lastAddon && !props.middleAddon) {
        base.push('rounded')
      } else if (props.firstAddon) {
        base.push('rounded-l')
      } else if (props.lastAddon) {
        base.push('rounded-r')
      }

      if (computedIconLeft.value) {
        base.push('pl-10')
      }

      if (computedIconRight.value) {
        base.push('pr-10')
      }

      return base
    })

    const computedType = computed(() => {
      if (props.options && props.type !== 'list') {
        return 'select'
      }

      if (props.buttonLabel || props.buttonIcon) {
        return 'button'
      }

      if (props.type === 'password' && passwordIsOpen.value) {
        return 'text'
      }

      return props.type
    })

    const computedIconLeft = computed(() => props.iconLeft ?? null)

    const computedIconRight = computed(() => {
      if (props.error) {
        return mdiAlertCircle
      }

      if (props.success) {
        return mdiCheckCircle
      }

      if (props.iconRight) {
        return props.iconRight
      }

      if (props.type === 'password') {
        return passwordIsOpen.value ? mdiLockOff : mdiAsterisk
      }

      if (props.type === 'list') {
        return mdiUnfoldMoreHorizontal
      }

      return null
    })

    const controlIconH = computed(() => props.type === 'textarea' ? 'h-full' : 'h-12')

    const passwordIsOpen = ref(false)

    const rightIconClickable = computed(() => props.type === 'password')

    const openPasswordToggle = e => {
      if (rightIconClickable.value) {
        passwordIsOpen.value = !passwordIsOpen.value
        emit('right-icon-click', e)
      }
    }

    return {
      computedValue,
      textColor,
      upperWrapperClass,
      wrapperClass,
      inputElClass,
      computedType,
      computedIconLeft,
      computedIconRight,
      controlIconH,
      rightIconClickable,
      openPasswordToggle
    }
  }
}
</script>
