<template>
  <div class="grid grid-cols-1 last:mb-0" :class="upperWrapperClass">
    <label v-if="label" class="block font-bold mb-2" :class="labelClass">
      {{ label }}
      <slot name="labelIcon"></slot>
    </label>
    <div :class="wrapperClass">
      <slot />
    </div>
    <field-help
      class="mt-1"
      :class="{'lg:col-start-2 lg:col-span-4 lg:-mt-5':horizontal}"
      :help="help"
      :error="error"
      :success="success"
    />
  </div>
</template>

<script>
import { controlTextColor } from '@/colors.js'
import { computed } from 'vue'
import FieldHelp from '@/components/FieldHelp'

export default {
  name: 'Field',
  components: {
    FieldHelp
  },
  props: {
    label: String,
    help: String,
    horizontal: Boolean,
    addons: Boolean,
    grouped: Boolean,
    multiline: Boolean,
    error: [Boolean, String],
    success: [Boolean, String]
  },
  setup (props, { slots }) {
    const upperWrapperClass = computed(() => {
      const base = [props.multiline ? '-mb-3' : 'mb-6']

      if (props.horizontal) {
        base.push('lg:gap-6 lg:grid-cols-5')
      }

      return base
    })

    const wrapperClass = computed(() => {
      const base = []
      const slotsLength = slots.default().length

      if (props.horizontal) {
        base.push('lg:col-span-4')

        if (!props.label) {
          base.push('lg:col-start-2')
        }
      }

      if (props.addons || props.grouped || props.multiline) {
        base.push('flex justify-start')
      } else {
        if (slotsLength > 1) {
          base.push('grid grid-cols-1 gap-3')
        }

        if (slotsLength === 2) {
          base.push('md:grid-cols-2')
        }

        if (slotsLength >= 3) {
          base.push('md:grid-cols-3')
        }
      }

      if (props.grouped) {
        base.push('space-x-3')
      }

      if (props.multiline) {
        base.push('flex-wrap')
      }

      return base
    })

    const labelClass = computed(() => {
      const base = []

      if (props.horizontal) {
        base.push('lg:mb-0')
      }

      const textColor = controlTextColor(props.error, props.success)

      if (textColor) {
        base.push(textColor)
      }

      return base
    })

    return {
      upperWrapperClass,
      wrapperClass,
      labelClass
    }
  }
}
</script>
