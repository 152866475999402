<template>
  <card-component :title="computedTitle" :icon="computedIcon" form>
    <highcharts class="chart" :options="highChartOptions" ref="chartRef"></highcharts>
    <template #footer v-if="path !== '/account/bidding'">
      <text-link to="/account/bidding" :icon="mdiFinance" label="Bekijk Biedstrategie" xsmall class="mr-5"/>
    </template>
  </card-component>
</template>

<script setup>
import filters from '@/filters'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { defineProps, computed } from 'vue'
import { mdiGasCylinder, mdiPowerPlug, mdiLink, mdiFinance } from '@mdi/js'

import TextLink from '@/components/TextLink'
import CardComponent from '@/components/CardComponent'

const { t } = useI18n()

const route = useRoute()
const path = computed(() => route.path)

const props = defineProps({
  selectedUser: {
    type: Object,
    default: () => { return {} }
  },
  type: {
    type: String,
    default: 'gas'
  },
  displayPrice: {
    type: Boolean,
    default: false
  }
})

const computedTitle = computed(() => {
  return t(`card_titles.${props.type}_volume_and_clicks`)
})

const computedIcon = computed(() => {
  return props.type === 'general_gas' ? mdiGasCylinder : mdiPowerPlug
})

const volumeYears = computed(() => {
  return props.selectedUser ? props.selectedUser.volumes.map(entry => entry.year) : []
})

const volumes = computed(() => {
  if (props.type === 'general_gas') {
    return props.selectedUser ? props.selectedUser.volumes.map(entry => entry.gas_volume) : []
  } else {
    return props.selectedUser ? props.selectedUser.volumes.map(entry => entry.electricity_volume) : []
  }
})

const yAxisTitle = computed(() => {
  return props.displayPrice ? 'Absolute prijs in euro' : 'Volume in MWh'
})

const series = computed(() => {
  if (props.type === 'general_gas') {
    if (props.displayPrice) {
      return [{
        name: `${t(props.type)} volume`,
        data: volumes.value,
        stack: props.type
      }]
    }
    return [{
      name: `${t(props.type)} volume`,
      data: volumes.value,
      stack: props.type
    }, {
      name: `${t(props.type)} kliks`,
      data: clicks.value,
      stack: props.type
    }]
  }

  if (props.displayPrice) {
    return [{
      name: `${t(props.type)} kliks`,
      data: clicks.value,
      stack: props.type
    }]
  }

  return [{
    name: `${t(props.type)} volume`,
    data: volumes.value,
    stack: props.type
  }, {
    name: `${t(props.type)} kliks`,
    data: clicks.value,
    stack: props.type
  }]
})

const clicks = computed(() => {
  const clicksObject = {}

  if (!props.selectedUser || !props.selectedUser.clicks || volumeYears.value.length === 0) {
    return []
  }

  for (const year of volumeYears.value) {
    clicksObject[year] = 0
  }

  for (const click of props.selectedUser.clicks) {
    const year = '20' + click.strip.match(/\d+/)[0]

    if ((props.type === 'general_gas' && click.index.includes('TTF')) ||
        (props.type === 'general_power' && click.index.includes('Power'))) {
      // clicksObject[year] += click.volume
      props.displayPrice ? clicksObject[year] += (click.price * click.volume) : clicksObject[year] += click.volume
    }
  }

  return Object.values(clicksObject)
})

const highChartOptions = computed({
  get () {
    return {
      chart: {
        styledMode: true,
        type: 'column',
        zoomType: 'xy',
        events: {
          addSeries: function () {
            const chart = this
            chart.hideLoading(t('loading'))
          },
          load: function () {
            const chart = this
            // chart.showLoading(t('loading'))

            const dimensions = filters.watermarkDimensions({
              chartWidth: chart.chartWidth,
              chartHeight: chart.chartHeight
            })

            chart.watermark = chart.renderer.image('/images/logo-wattanders-graph.svg', dimensions.svgPosX, dimensions.svgPosY, dimensions.svgWidth, dimensions.svgHeight).add()
          },
          redraw: function () {
            const chart = this
            const extremesY = chart.yAxis[0].getExtremes()
            const extremesX = chart.xAxis[0].getExtremes()

            const dimensions = filters.watermarkDimensions({
              chartWidth: chart.chartWidth,
              chartHeight: chart.chartHeight
            })

            if (chart.watermark) {
              chart.watermark.attr({ x: dimensions.svgPosX, y: dimensions.svgPosY })
            } else if (extremesX.max !== undefined && extremesX.max !== null) {
              chart.watermark = chart.renderer.image('/images/profiteia_logo_icon.svg', dimensions.svgPosX, dimensions.svgPosY, dimensions.svgWidth, dimensions.svgHeight).add()
            }
          }
        }
      },
      credits: {
        enabled: false,
        href: 'https://profiteia.io',
        text: 'profiteia.io'
      },
      title: {
        text: undefined
      },
      xAxis: {
        categories: volumeYears.value,
        title: {
          text: 'Jaar'
        }
      },
      yAxis: [
        {
          title: {
            text: yAxisTitle.value
          }
        }
      ],
      tooltip: {
        pointFormat: `{point.y:.2f} ${props.displayPrice ? '€' : 'MWh'}`
      },
      series: series.value,
      exporting: {
        enabled: false
      },
      plotOptions: {
        column: {
          grouping: false
        },
        series: {
          color: '#FF0000',
          marker: {
            enabled: false
          }
        }
      },
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          }
        }]
      }
    }
  }
})

</script>
