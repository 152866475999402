<template>
  <icon
    :path="icon"
    w="w-10"
    :h="h"
    class="absolute top-0 z-10"
    :class="[
      isRight ? 'right-0' : 'left-0',
      clickable ? 'cursor-pointer' : 'pointer-events-none',
      textColor
    ]"
    @click.prevent="iconClick"
  />
</template>

<script>
import Icon from '@/components/Icon'

export default {
  name: 'ControlIcon',
  components: {
    Icon
  },
  props: {
    icon: String,
    h: String,
    isRight: Boolean,
    clickable: Boolean,
    textColor: {
      type: String,
      default: 'text-gray-500'
    }
  },
  emits: ['icon-click'],
  setup (props, { emit }) {
    const iconClick = e => {
      emit('icon-click', e)
    }

    return {
      iconClick
    }
  }
}
</script>
