<template>
  <component
    :is="is"
    :class="[componentClass, darkBg]"
    class="bg-white border border-gray-100"
    @submit="submit"
  >
    <header
      v-if="header"
      :class="[headerBorder, headerFooterBorder, collapsible ? collapsibleHeaderClass : '', { 'p-6': !hasTable && !smallerPadding, 'py-6 px-4': smallerPadding }]"
      @click.prevent="headerClick"
    >
      <slot name="header" :collapsed="computedCollapsed" :icon="computedHeaderIcon" />
    </header>
    <header
      v-else-if="title"
      class="flex items-stretch"
      :class="[headerBorder, headerFooterBorder, { 'cursor-pointer': collapsible }]"
      @click.prevent="headerClick"
    >
      <p class="text-sm dark:text-emerald-500 flex items-center py-3 flex-grow font-normal" :class="[smallerPadding || icon ? 'px-4' : 'px-6']">
        <icon v-if="icon" :path="icon" class="mr-3" />
        {{ title }} 
        <pill v-if="isPro" type="success" text="Pro" class="ml-2" small/>
      </p>
      <a
        v-if="computedHeaderIcon"
        class="flex items-center py-3 px-4 justify-center cursor-pointer"
        @click.prevent="headerIconClick"
      >
        <icon :path="computedHeaderIcon" />
      </a>
      <a
        v-else-if="sourceHeader"
        :href="sourceHeaderUrl"
        target="_blank"
        class="flex items-center py-3 px-4 justify-center text-xs"
      >
        {{ sourceHeader }}
      </a>
    </header>
    <div v-if="empty" class="text-center py-24 lg:py-12 text-gray-500 dark:text-gray-400">
      <div class="mb-6">No items to show&hellip;</div>
      <div>
        <jb-button :icon="mdiPlusCircle" label="Add first item" :outline="darkMode" />
      </div>
    </div>
    <div
      v-else
      :class="[computedCollapsed ? collapsedClass : null, { 'p-6': !hasTable && !smallerPadding, 'py-6 px-4': smallerPadding, 'flex-1': flex || flexRow || footer }]"
    >
      <slot />
    </div>
    <div v-if="footer" :class="[footerWithoutBorder ? null : headerFooterBorder, computedCollapsed ? collapsedClass : null]" class="p-2 px-4 border-t">
      <slot name="footer"/>
    </div>
  </component>
</template>

<script>
import { mdiPlusCircle, mdiChevronUp, mdiChevronDown } from '@mdi/js'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import Icon from '@/components/Icon'
import JbButton from '@/components/JbButton'
import Pill from '@/components/Pill'

export default {
  name: 'CardComponent',
  components: {
    Icon,
    JbButton,
    Pill
  },
  props: {
    darkBg: {
      type: String,
      default: 'dark:bg-gray-900 dark:border-gray-900'
    },
    title: String,
    icon: String,
    headerIcon: String,
    hasTable: Boolean,
    empty: Boolean,
    smallerPadding: Boolean,
    footerWithoutBorder: Boolean,
    flex: Boolean,
    flexRow: Boolean,
    form: Boolean,
    hoverable: Boolean,
    collapsible: Boolean,
    isPro: Boolean,
    sourceHeader: String,
    sourceHeaderUrl: String,
    collapsibleHeaderClass: {
      type: String,
      default: 'cursor-pointer'
    },
    collapsedClass: {
      type: String,
      default: 'hidden'
    },
    rounded: {
      type: String,
      default: 'md:rounded'
    }
  },
  emits: ['header-icon-click', 'submit'],
  setup (props, { emit, slots }) {
    const store = useStore()

    const darkMode = computed(() => store.state.display.darkMode)

    const headerFooterBorder = 'border-gray-100 dark:border-gray-700'

    const headerBorder = 'border-b'

    const submit = e => {
      emit('submit', e)
    }

    const is = computed(() => props.form ? 'form' : 'div')

    const componentClass = computed(() => {
      const base = [
        props.rounded
      ]

      if (props.flexRow) {
        base.push('flex flex-row')
      } else if (props.flex || footer.value) {
        base.push('flex flex-col')
      } else {
        base.push('block')
      }

      if (props.hoverable) {
        base.push('hover:shadow-lg transition-shadow duration-500')
      }

      return base
    })

    const header = computed(() => slots.header && !!slots.header())

    const footer = computed(() => slots.footer && !!slots.footer())

    const collapsed = ref(true)

    const computedCollapsed = computed(() => props.collapsible ? collapsed.value : false)

    const headerIconClick = e => {
      emit('header-icon-click', e)
    }

    const headerClick = () => {
      if (props.collapsible) {
        collapsed.value = !collapsed.value
      }
    }

    const computedHeaderIcon = computed(() => {
      if (props.headerIcon) {
        return props.headerIcon
      }

      if (props.collapsible) {
        return collapsed.value ? mdiChevronDown : mdiChevronUp
      }

      return null
    })

    return {
      darkMode,
      headerFooterBorder,
      headerBorder,
      headerIconClick,
      headerClick,
      submit,
      is,
      componentClass,
      computedCollapsed,
      computedHeaderIcon,
      header,
      footer,
      mdiPlusCircle,
      mdiChevronUp,
      mdiChevronDown
    }
  }
}
</script>
