import AuthenticatedClient from './clients/AuthenticatedClient'
import Client from './clients/Client'

const resource = '/statistics'

export default {
  getGIEForCountry (payload) {
    return AuthenticatedClient.post(`${resource}/gie`, payload)
  },
  getICE (payload) { 
    return AuthenticatedClient.post(`${resource}/ice`, payload)
  },
  getICELive () { 
    return AuthenticatedClient.get(`${resource}/ice/live/dutch-ttf`)
  },
  getICESettlements (payload) { 
    return AuthenticatedClient.post(`${resource}/ice/settlements`, payload)
  },
  getGeneric (payload) { 
    return AuthenticatedClient.post(`${resource}/generic`, payload)
  }
}
