<template>
  <span :class="spanClass">
    <svg viewBox="0 0 24 24" :width="svgSize" :height="svgSize" class="inline-block">
      <path fill="currentColor" :d="path" />
    </svg>
    <update-mark :color="updateMark" :tr="updateMarkTr"/>
  </span>
</template>

<script>
import { computed } from 'vue'
import UpdateMark from '@/components/UpdateMark.vue'

export default {
  name: 'Icon',
  components: {
    UpdateMark
  },
  props: {
    path: {
      required: true
    },
    w: String,
    h: String,
    size: [String, Number],
    updateMark: String,
    updateMarkTr: String
  },
  setup (props) {
    const spanClass = computed(() => {
      const base = [
        'inline-flex',
        'justify-center',
        'items-center',
        props.w ?? 'w-6',
        props.h ?? 'h-6'
      ]

      if (props.updateMark) {
        base.push('relative')
      }

      return base
    })

    const svgSize = computed(() => {
      return props.size ?? 16
    })

    return {
      spanClass,
      svgSize
    }
  }
}
</script>
