<template>
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="150" viewBox="-7.5 0 330 80">
    <path class="st0" d="M39.5 0C17.7 0 0 17.9 0 40s17.7 40 39.5 40S79 62.1 79 40 61.3 0 39.5 0z"/>
    <path d="M39.5 74.9C20.4 74.9 5.1 59.3 5 40c0-3.4.5-6.7 1.4-9.8.2-.8.9-4.2 4.5-6 1.1-.5 2.1-.7 3.2-.7 2.9 0 4.8 1.8 5.8 3.2.9 1.3 1.4 2.5 1.5 2.8l5.4 13.1 9.6-15.4c.5-.8 1.4-1.2 2.3-1.1.9.1 1.7.8 1.9 1.7l7 25 6.7-17-2.1-.8c2.1-1.7 7.9-8.7 9-10.2-.3 2-.3 12.4-.2 13.3l-2.1-.7L49.5 61c-.4.9-1.3 1.5-2.3 1.5-1 0-1.9-.7-2.1-1.7l-7.5-26.6L28.4 49c-.5.7-1.3 1.2-2.2 1.1-.9-.1-1.6-.6-2-1.5L17 31.3v-.2s0-.1-.2-.4c-.1-.3-.4-.7-.7-1.1-.6-.8-1.3-1.3-2.1-1.3-.3 0-.7.1-1.3.3-.6.2-1.5 1.7-1.7 2.4v.6c-.9 2.6-1.3 5.4-1.3 8.4 0 16.7 13.3 30.1 29.8 30.2 16.4 0 29.8-13.5 29.8-30.2S56 9.9 39.5 9.8c-6.3 0-12.1 2-16.9 5.3-1.1.7-2.5.5-3.3-.6-.7-1.1-.5-2.6.6-3.3 5.5-3.9 12.3-6.1 19.5-6.1C58.6 5.1 73.9 20.7 74 40c-.1 19.3-15.4 34.9-34.5 34.9z" fill="#fff"/>
    <path class="st0" d="M113.6 69.3h-5.7L106 53.4h-.2l-6.4 15.9h-6.3l-3.5-22.5h6.3l1.4 15.1h.3l5.9-15.1h6.7l1.6 15.2h.3l6.3-15.2h5.3zM143 62c-.3 1.7-.5 4.1-.5 7.1h-5.7v-2.4h-.1c-.8 1.1-2.3 2.9-5.5 2.9H128c-4.2 0-5.3-2.3-5.3-4.9l.3-2.6c.5-3.9 3.2-6.2 7.4-6.2h7.2l.6-4c0-.6 0-1.2-1.7-1.2h-10.1l.5-3.5c1.3-.6 3.7-.9 6.2-.9h5.3c4.3 0 6 1.9 6 5.4L143 62zm-11.4-2.6c-1.4 0-2.1.6-2.3 2L129 64c0 1.4.7 1.5 1.8 1.5h2.7c1.4 0 3-.5 3.4-2.6l.5-3.5h-5.8zm42.7-8.4h7.1l.6-3.9h-7.1l.9-6.5-6.1.7-.8 5.9H158l.9-6.5-6.1.7-.8 5.9h-3.8l-.5 3.9h3.8L149.6 65c0 4.2 2.4 4.7 5.7 4.7 1.5 0 4.3-.1 6.9-1.4l.4-3.1H157c-1.5 0-1.3-.9-1.3-1.7l1.7-12.3h10.8L166.3 65c0 4.2 2.4 4.7 5.7 4.7 1.5 0 4.2-.1 6.9-1.4l.4-3.1h-5.4c-1.5 0-1.3-.9-1.3-1.7l1.7-12.5z"/>
    <path d="M203.5 62c-.3 1.7-.5 4.1-.5 7.1h-5.7v-2.4h-.1c-.8 1.1-2.3 2.9-5.5 2.9h-3.2c-4.2 0-5.3-2.3-5.3-4.9l.3-2.6c.6-3.9 3.2-6.2 7.4-6.2h7.2l.6-4c0-.6 0-1.2-1.7-1.2h-10l.5-3.5c1.3-.6 3.7-.9 6.2-.9h5.3c4.3 0 6 1.9 6 5.4L203.5 62zm-11.4-2.6c-1.4 0-2.1.6-2.3 2l-.3 2.6c0 1.4.7 1.5 1.8 1.5h2.7c1.4 0 3-.5 3.4-2.6l.5-3.5h-5.8zm35.8 9.7h-6.1l2.4-17c0-.7-.4-1.1-1.4-1.1h-3.2c-1.1 0-2.4.2-3 .4l-2.5 17.7H208l3.1-22.3h5.6v2.3c1.4-1.8 3.7-2.8 6.8-2.8h2.1c4 0 5 1.4 5 4.4l-2.7 18.4zm25.1 0h-5.7v-1.7c-1.4 1.3-2.6 2.2-5.8 2.2h-2.9c-4.2 0-5.3-2.4-4.8-5.6l1.6-11.7c.5-3.9 3.6-6 7.3-6h2.6c2.8 0 4 .6 4.6 1.2l1.3-9.3h6.1L253 69.1zm-6.8-18.3h-2c-2.2 0-2.5 1.1-2.7 2.3l-1.3 9.8c-.3 1.7.6 2.1 1.9 2.1h2.1c.8 0 2.1-.1 3.5-1.1l1.7-12.5c-.8-.2-2-.6-3.2-.6m53.6.7H297c-1.1 0-4.7.2-6 .6L288.6 69h-6.1l3.1-22.3h5.5v2.2c1.6-1.3 3.8-2.6 7-2.6.8 0 1.6 0 2.4.2l-.7 5zm19.7-.8h-9.9c-1.1 0-1.8.3-1.9 1.3l-.3 1.9c0 .6.3.9 1.3 1l5.6.5c3.7.4 5.1 1.6 5.1 4.6l-.6 4c-.5 3.6-3.4 5.6-8 5.6h-5.4c-1.9 0-4.5-.3-5.6-1l.5-3.5H311c1.7 0 2.1-.4 2.3-1.5l.3-2.1c0-.7-.1-.9-1.3-1.1l-5.6-.6c-3.5-.3-4.8-1.1-4.8-4.5l.5-3.5c.5-3.4 3.2-5.7 8-5.7h4.3c1.9 0 3.9.2 5.3.9l-.5 3.7zm-43.6 8.7l3.2-.6 1-7.3c0-4.3-3.2-5.2-7.7-5.2h-2.9c-4.7 0-8.6 1.9-9.3 6.9l-1.4 10.4c0 4.4 2.4 6 7.5 6h5.4c2.4 0 4.1-.4 5.8-1.3l.4-3.1h-10.7c-1.5 0-2.3-.5-2.3-1.7l.6-4.1h10.4zm-9.5-7c.2-1.5.8-2.1 2.3-2.1h3.9c1.4 0 1.8.3 1.8 1.5l-.5 3.8H266l.4-3.2z" fill="#1b1c20"/>
  </svg>
</template>

<script>
export default {
  name: 'ProfiteiaLogoText'
}
</script>

<style>
  .st0 {
    fill:#00a5ea
  }
</style>
