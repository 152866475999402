<template>
  <div v-if="computedHelp" class="text-xs" :class="[textColor]">{{ computedHelp }}</div>
</template>

<script>
import { controlTextColor } from '@/colors.js'
import { computed } from 'vue'

export default {
  name: 'FieldHelp',
  props: {
    help: String,
    error: [Boolean, String],
    success: [Boolean, String]
  },
  setup (props) {
    const computedHelp = computed(() => props.error && typeof props.error === 'string' ? props.error : props.help)

    const textColor = computed(() => {
      const color = controlTextColor(props.error, props.success)

      return color ?? 'text-gray-500 dark:text-gray-400'
    })

    return {
      computedHelp,
      textColor
    }
  }
}
</script>
