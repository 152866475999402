<template>
  <section class="p-6 pt-8">
    <level>
      <ul>
        <li
          v-for="(title, index) in titleStack"
          :key="index"
          class="title-stack-item inline-block pr-3 text-2xl text-gray-500 last:pr-0 last:font-black last:text-black
            dark:text-gray-100">
          {{ title }}
        </li>
      </ul>
    </level>
  </section>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { mdiCreditCard, mdiEmailNewsletter } from '@mdi/js'
import Level from '@/components/Level'

export default {
  name: 'TitleBar',
  components: {
    Level
  },
  props: {
    titleStack: {
      type: Array,
      default: () => []
    }
  },
  setup () {
    const store = useStore()
    const receivesNewsletter = computed(() => store.state.auth.receivesNewsletter)
    const isNewsletterModalActive = computed({
      get: () => store.state.display.isNewsletterModalActive,
      set: value => store.dispatch('display/newsletterModalToggle', value)
    })

    return {
      mdiCreditCard,
      mdiEmailNewsletter,
      isNewsletterModalActive,
      receivesNewsletter
    }
  }
}
</script>

<style scoped>
li.title-stack-item:not(:last-child):after {
  content: '/';
  @apply inline-block pl-3;
}
</style>
