import PrivateClient from './clients/PrivateClient'
const resource = '/private/api'

export default {
  getShips () {
    return PrivateClient.get(`${resource}/por/v1/ships`)
  },
  generateModel (form) {
    return PrivateClient.post(`${resource}/por/v1/model`, form)
  }
}
