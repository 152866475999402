import AuthenticatedClient from './clients/AuthenticatedClient'
import Client from './clients/Client'

const resource = '/auth'

export default {
  activate (activationCode) {
    return Client.get(`${resource}/activate/${activationCode}`)
  },
  register (payload) {
    return Client.post('/admin/register', payload)
  },
  authenticate (payload) {
    return Client.post(`${resource}/login`, payload)
  },
  recoverPassword (payload) {
    return Client.post(`${resource}/recover`, payload)
  },
  checkRecoveryCode (recoveryCode) {
    return Client.get(`${resource}/recover/${recoveryCode}`)
  },
  resetPassword (payload) {
    return Client.post(`${resource}/reset`, payload)
  },
  resendActivation (payload) {
    return Client.post(`${resource}/resend-activation`, payload)
  },
  getSubscriptions () {
    return Client.get(`${resource}/subscriptions`)
  },
  storeRouteChange (payload) {
    return Client.post('/navigate/user/route', payload)
  },
  storeRouteError (payload) {
    return Client.post('/navigate/user/route/error', payload)
  },
  protected (accessToken) {
    return AuthenticatedClient.get(`${resource}/protected`)
  },
  logout () {
    return AuthenticatedClient.get(`${resource}/logout`)
  },
  getInvoices () {
    return AuthenticatedClient.get(`${resource}/invoices`)
  },
  userSubscriptionInformation () {
    return AuthenticatedClient.get(`${resource}/user/subscription`)
  },
  userInformation () {
    return AuthenticatedClient.get(`${resource}/user`)
  },
  updateUserInformation (payload) {
    return AuthenticatedClient.post(`${resource}/user`, payload)
  },
  updateVolumes (payload) {
    return AuthenticatedClient.post(`${resource}/user/volumes`, payload)
  },
  updateVolumesAdmin (payload) {
    return AuthenticatedClient.post('/admin/user/volumes', payload)
  },
  createUserPassword (payload) {
    return AuthenticatedClient.post(`${resource}/email_password`, payload)
  },
  updateUserPassword (payload) {
    return AuthenticatedClient.post(`${resource}/password`, payload)
  },
  getUsersAdministration (payload) {
    return AuthenticatedClient.get('/admin/users')
  },
  addUserClick (payload) {
    return AuthenticatedClient.post('/admin/user/click/add', payload)
  },
  deleteUserClick (payload) {
    return AuthenticatedClient.post('/admin/user/click/delete', payload)
  },
  updateUser (payload) { 
    return AuthenticatedClient.post('/admin/user/update', payload)
  },
  deleteUser (payload) { 
    return AuthenticatedClient.post('/admin/user/delete', payload)
  }  
}
