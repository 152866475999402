<template>
  <main-section>
    <div class="grid grid-cols-1 gap-6 mb-6 xl:grid-cols-1">
      <card-component :title="$t('card_titles.contact_us')" :icon="mdiEmailOpen" @submit.prevent="submitProfile" form>
        <field :label="$t('general_subject')">
          <control v-model="contactForm.contactSubject" name="subject" required/>
        </field>
        <field :label="$t('general_name')" :help="$t('general_name_help_required')">
          <control v-model="contactForm.contactFromName" name="name" autocomplete="name" required/>
        </field>
        <field :label="$t('general_email')" :help="$t('general_email_help_required')">
          <control v-model="contactForm.contactFromEmail" name="email" type="email" autocomplete="email" required/>
        </field>
        <field :label="$t('general_question')" :help="$t('general_question_help')">
          <control type="textarea" :placeholder="$t('general_question_placeholder')" name="message" v-model="contactForm.contactMessage" required/>
        </field>
        <field label="Captcha" :help="$t('general_captcha_help')">
          <template v-slot:labelIcon>
            <icon :path="mdiReload" size="12" @click="getCaptcha()"/>
          </template>
          <control v-model="contactForm.captchaResult" name="name" autocomplete="name" required/>
        </field>
        <img v-if="captchaData !== ''" :src="`data:image/png;base64,${captchaData}`" />
        <!-- key: {{ contactForm.captchaKey }} -->
        <input type="hidden" name="captchaKey" v-model="contactForm.captchaKey"/>
        <template #footer>
          <level>
            <jb-buttons>
              <jb-button type="submit" :label="$t('general_send')" color="info" @click="submitContactForm()" />
            </jb-buttons>
          </level>
        </template>
      </card-component>
      <!-- <tweets-card class="invisible"/> -->
    </div>
  </main-section>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { mdiAccount, mdiReload, mdiAccountCircle, mdiLock, mdiTwitter, mdiMail, mdiFormTextboxPassword, mdiLifebuoy, mdiEmailOpen } from '@mdi/js'
// import { buttonMenuOptions } from '@/button-menu-sample-options.js'
import MainSection from '@/components/MainSection'
import CardComponent from '@/components/CardComponent'
// import TweetsCard from '@/components/TweetsCard'
// import TitleBar from '@/components/TitleBar'
// import HeroBar from '@/components/HeroBar'
// import Divider from '@/components/Divider'
import Icon from '@/components/Icon'
import Field from '@/components/Field'
import Control from '@/components/Control'
// import UserCard from '@/components/UserCard'
import JbButtons from '@/components/JbButtons'
import JbButton from '@/components/JbButton'
import Level from '@/components/Level'
// import TextLink from '@/components/TextLink'
// import ButtonMenu from '@/components/ButtonMenu'
// import BottomOtherPagesSection from '@/components/BottomOtherPagesSection'
import Repository from '@/repositories/RepositoryFactory'

export default {
  name: 'Contact',
  components: {
    Icon,
    MainSection,
    // HeroBar,
    // TitleBar,
    CardComponent,
    // TweetsCard,
    // Divider,
    Field,
    Control,
    // UserCard,
    JbButtons,
    JbButton,
    Level
    // TextLink,
    // ButtonMenu,
    // BottomOtherPagesSection
  },
  setup () {
    const { t } = useI18n()
    const store = useStore()
    const EmailRepository = Repository.get('email')
    const captchaData = ref('')
    const isLoading = ref(false)
    const titleStack = ref(['Contact'])

    const profileForm = reactive({
      name: store.state.auth.userName,
      email: store.state.auth.userEmail
    })

    const passShowHideClicked = ref(false)

    const passwordForm = reactive({
      captchaKey: null,
      captchaResult: null,
      contactFromName: null,
      contactFromEmail: null,
      contactSubject: null,
      contactMessage: null
    })

    const contactForm = reactive({
      captchaKey: null,
      captchaResult: null,
      contactFromName: null,
      contactFromEmail: null,
      contactSubject: null,
      contactMessage: null
    })

    const submitProfile = () => {}

    const getCaptcha = () => {
      isLoading.value = true

      return EmailRepository.getCaptcha().then(response => {
        captchaData.value = response.data.captcha_data
        isLoading.value = false
        contactForm.captchaKey = response.data.key
      }).catch(function (error) {
        console.log(error)
      })
    }

    const submitContactForm = () => {
      isLoading.value = true

      setTimeout(() => {
        isLoading.value = false

        EmailRepository.submitContactForm(contactForm).then(response => {
          store.dispatch('display/pushMessage', t('general_message_sent'))

          contactForm.captchaKey = null
          contactForm.captchaResult = null
          contactForm.contactFromName = null
          contactForm.contactFromEmail = null
          contactForm.contactSubject = null
          contactForm.contactMessage = null

          getCaptcha()
        }).catch((error) => {
          store.dispatch('display/pushMessage', t(error.response.data.detail))
        })
      }, 500)
    }

    onMounted(() => {
      // console.log('Mounted: ', captchaData)
      getCaptcha()
      // console.log('After Mounted: ', captchaData)
    })

    return {
      isLoading,
      captchaData,
      profileForm,
      titleStack,
      passShowHideClicked,
      passwordForm,
      submitProfile,
      contactForm,
      mdiAccount,
      mdiAccountCircle,
      mdiLock,
      mdiReload,
      mdiMail,
      mdiTwitter,
      mdiFormTextboxPassword,
      mdiLifebuoy,
      getCaptcha,
      submitContactForm,
      mdiEmailOpen
      // buttonMenuOptions
    }
  }
}
</script>
