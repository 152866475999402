<template>
  <div v-if="isNavBarVisible">
    <nav class="bg-white fixed w-full z-20 top-0 start-0 h-16">
      <div class="max-w-screen-xl mx-auto flex flex-wrap items-center justify-between">
        <!-- Logo aligned to the left -->
        <profiteia-logo-text class="flex-shrink-0 absolute left-3 top-1 pt-2"/>

        <!-- Button aligned to the right -->
        <div class="flex absolute right-2 md:pr-2 md:pt-1 top-3">
          <jb-button outline to="/account/profile" :label="company" noFocusRing noBorder color="azure" class="mr-2 hidden lg:block"/>
          <jb-button :icon="mdiEmailOpen" to="/contact" label="Contact" noFocusRing color="azure" class="border-gray-100"/>

          <button data-collapse-toggle="navbar-sticky" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-black md:hidden pr-0 pl-2" aria-controls="navbar-sticky" aria-expanded="false" @click="toggleMobileMenuVisible">
            <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 15 13">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
            </svg>
          </button>
        </div>
      </div>
    </nav>
    <div class="hidden lg:block top-14 left-0 right-0 fixed flex bg-white h-14 z-30 border-b-2 border-gray-100 navbar-top-menu">
      <template v-for="(menuGroup, index) in menu" :key="`m-${index}`">
        <top-menu-list :menu="menuGroup"/>
      </template>
    </div>
    <div v-if="isMobileMenuVisible" class="items-center bg-white justify-between w-full md:flex md:w-auto md:order-1" id="navbar-sticky">
      <template v-for="(menuGroup, index) in menu" :key="`m-${index}`">
        <top-menu-list :menu="menuGroup" isMobile/>
      </template>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import {
  mdiForwardburger, mdiBackburger, mdiClose, mdiDotsVertical, mdiMenu,
  mdiHome, mdiCrown, mdiCheck, mdiAccount, mdiCogOutline, mdiLogin,
  mdiLogout, mdiBell, mdiEmailNewsletter, mdiThemeLightDark, mdiEmailOpen,
  mdiInformationOutline, mdiEarth, mdiFlag
} from '@mdi/js'
import menu from '@/menu.js'
import JbButton from '@/components/JbButton'
import NavBarItem from '@/components/NavBarItem'
import NavBarItemLabel from '@/components/NavBarItemLabel'
import NavBarMenu from '@/components/NavBarMenu'
import NavBarMenuDivider from '@/components/NavBarMenuDivider'
import ProfiteiaLogoText from '@/components/ProfiteiaLogoText'
import TopMenuList from '@/components/TopMenuList'
import Icon from '@/components/Icon'

const store = useStore()
const { t } = useI18n()
const currentLocale = computed(() => t('language_short'))

const isAsideRightActive = computed(() => store.state.display.isAsideRightActive)
const isNavBarVisible = computed(() => !store.state.display.isFormScreen)

const isMobileMenuVisible = computed(() => store.state.display.isMobileMenuVisible)
const isAsideMobileExpanded = computed(() => store.state.display.isAsideMobileExpanded)
const isAsideCompact = computed(() => store.state.display.isAsideCompact)
const isAsideHiddenLayout = computed(() => store.state.display.isAsideHiddenLayout)
const firstname = computed(() => store.state.auth.firstname)
const email = computed(() => store.state.auth.email)
const company = computed(() => store.state.auth.company)
const isAuthenticated = computed(() => store.state.auth.isAuthenticated)
const isSuperUser = computed(() => store.state.auth.isSuperUser)
const isMenuNavBarActive = ref(false)
const updatesClicked = ref(false)
const mobileMenuVisible = ref(true)
const menuToggleMobileIcon = computed(() => isAsideMobileExpanded.value ? mdiBackburger : mdiForwardburger)
const menuToggleIcon = computed(() => isAsideHiddenLayout.value ? mdiMenu : isAsideCompact.value ? mdiForwardburger : mdiBackburger)
const updatesUpdateMark = computed(() => !updatesClicked.value && store.state.display.updatesStatus ? 'warning' : null)
const menuNavBarToggleIcon = computed(() => isMenuNavBarActive.value ? mdiClose : mdiDotsVertical)

const menuToggleMobile = () => store.dispatch('display/asideMobileToggle')
const menuToggle = () => store.dispatch(isAsideHiddenLayout.value ? 'display/asideVisibilityToggle' : 'display/asideCompactToggle')
const asideRightToggle = () => {
  updatesClicked.value = true
  store.dispatch('display/asideRightToggle')
}
const logoutAction = () => {
  store.commit('auth/clearTokens')
  store.commit('auth/clearUserData')
  store.dispatch('display/pushMessage', t('messages.logged_out'))
}
const setLocale = (locale) => store.dispatch('display/setLocale', locale)
const toggleLightDark = () => store.dispatch('display/darkMode')
const toggleMobileMenuVisible = () => store.dispatch('display/mobileMenuToggle')

onMounted(() => {
  menuToggle()
})
</script>
