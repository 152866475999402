<template>
  <div v-if="color" class="w-2.5 h-2.5 rounded-full absolute" :class="componentClass"></div>
</template>

<script>
import { colorsBg, colorsBorders } from '@/colors.js'
import { computed, ref } from 'vue'

export default {
  name: 'UpdateMark',
  props: {
    color: String,
    tr: String
  },
  setup (props) {
    const hasPing = ref(true)

    setTimeout(() => {
      hasPing.value = false
    }, 1000)

    const componentClass = computed(() => {
      const base = [
        props.tr ?? 'top-0 right-0',
        ...colorsBg[props.color],
        ...colorsBorders[props.color]
      ]

      if (hasPing.value) {
        base.push('animate-ping')
      }

      return base
    })

    return {
      componentClass
    }
  }
}
</script>
