<template>
  <icon :path="icon" class="transition-colors" :update-mark="updateMark" />
  <span v-if="to" class="px-2 transition-colors" :class="{'lg:hidden':desktopIconOnly}"><router-link :to="to">{{ label }}</router-link></span>
  <span v-else class="px-2 transition-colors" :class="{'lg:hidden':desktopIconOnly}">{{ label }}</span>
</template>

<script>
import Icon from '@/components/Icon'

export default {
  name: 'NavBarItemLabel',
  components: { Icon },
  props: {
    to: {
      type: String
    },
    icon: {
      type: String
    },
    label: {
      type: String
    },
    desktopIconOnly: Boolean,
    updateMark: String
  }
}
</script>
