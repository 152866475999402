import i18n from '@/i18n'

export default {
  namespaced: true,
  state: {
    /* FormScreen - fullscreen form layout (e.g. login page) */
    isMobileMenuVisible: false,
    isFormScreen: false,
    /* Aside */
    isAsideVisible: true,
    isAsideCompact: false,
    isAsideMobileExpanded: false,
    secondaryMenu: null,
    isAsideHiddenLayout: false,
    /* AsideRight */
    isAsideRightActive: false,
    /* Layout */
    isBoxedLayout: false,
    isXl: false,
    isLg: false,
    isMd: false,
    isSm: false,
    /* Snackbar */
    messages: [],
    /* Dark mode */
    darkMode: false,
    isNewsletterModalActive: false,
    syncedCharts: {},
    forcedUpdate: false
  },
  mutations: {
    /* A fit-them-all commit */
    basic (state, payload) {
      state[payload.key] = payload.value
    },

    layoutBreakpoints (state, payload) {
      state.isXl = payload.isXl
      state.isLg = payload.isLg
      state.isMd = payload.isMd
      state.isSm = payload.isSm
    },

    pushMessage (state, payload) {
      state.messages.push(payload)
    },

    shiftMessage (state) {
      state.messages.shift()
    },

    resetSyncedCharts (state, payload) {
      const syncId = payload.syncId
      state.syncedCharts[syncId] = {}
    },

    syncCharts (state, payload) {
      // console.log(payload)
      const syncId = payload.syncId
      const syncWhat = payload.syncWhat
      const syncDimensions = payload.syncDimensions
      const uuid = payload.uuid

      // Add empty object if syncId not yet in syncedCharts
      if (Object.prototype.hasOwnProperty.call(state.syncedCharts, syncId) === false) {
        state.syncedCharts[syncId] = { syncWhat }
      }

      state.syncedCharts[syncId][uuid] = { syncDimensions }
      // console.log(state.syncedCharts)
    }
  },
  actions: {
    resetSyncedCharts ({ commit, state }, payload = null) {
      // const syncId = payload.syncId
      commit('resetSyncedCharts', payload)
    },
    syncChart ({ commit, state }, payload = null) {
      commit('syncCharts', payload)
    },
    mobileMenuToggle ({ commit, state }, payload = null) {
      const isShow = payload !== null ? payload : !state.isMobileMenuVisible

      commit('basic', {
        key: 'isMobileMenuVisible',
        value: isShow
      })
    },
    asideCompactToggle ({ commit, state }, payload = null) {
      const isShow = payload !== null ? payload : !state.isAsideCompact

      document.documentElement.classList[isShow ? 'add' : 'remove']('aside-compact')
      // rootClassSet(isShow, ['asideCompact'])

      commit('basic', {
        key: 'isAsideCompact',
        value: isShow
      })
    },
    asideRightToggle ({ commit, state }, payload = null) {
      const isShow = payload !== null ? payload : !state.isAsideRightActive

      document.documentElement.classList[isShow ? 'add' : 'remove']('m-clipped', 'mobile-aside-right-active')
      // rootClassSet(isShow, ['mClipped', 'mobileAsideRightActive'])

      commit('basic', {
        key: 'isAsideRightActive',
        value: isShow
      })
    },

    asideMobileToggle ({ commit, state, dispatch }, payload = null) {
      const isShow = payload !== null ? payload : !state.isAsideMobileExpanded

      document.documentElement.classList[isShow ? 'add' : 'remove']('m-clipped', 'mobile-aside-active')
      // rootClassSet(isShow, ['mClipped', 'mobileAsideActive'])

      commit('basic', {
        key: 'isAsideMobileExpanded',
        value: isShow
      })
    },

    secondaryMenuToggle ({ commit, state }, payload) {
      commit('basic', {
        key: 'secondaryMenu',
        value: payload
      })
    },

    newsletterModalToggle ({ commit, state }, payload) {
      commit('basic', {
        key: 'isNewsletterModalActive',
        value: payload
      })
    },

    forceUpdate ({ commit, state }, payload) {
      const forcedUpdate = payload !== null ? payload : !state.forcedUpdate
      commit('basic', {
        key: 'forcedUpdate',
        value: forcedUpdate
      })
    },

    asideHiddenLayoutToggle ({ commit, state, dispatch }, payload = null) {
      const isHide = payload !== null ? payload : !state.isAsideHiddenLayout

      document.documentElement.classList[isHide ? 'add' : 'remove']('aside-none')
      // rootClassSet(isHide, 'asideNone')

      commit('basic', {
        key: 'isAsideHiddenLayout',
        value: isHide
      })

      dispatch('asideVisibilityToggle', !isHide)
    },

    asideVisibilityToggle ({ commit, state }, payload = null) {
      const isVisible = payload !== null ? payload : !state.isAsideVisible

      commit('basic', {
        key: 'isAsideVisible',
        value: isVisible
      })
    },

    formScreenToggle ({ commit, state }, payload) {
      commit('basic', {
        key: 'isFormScreen',
        value: payload
      })

      if (!state.isAsideHiddenLayout) {
        commit('basic', {
          key: 'isAsideVisible',
          value: !payload
        })
      }

      document.documentElement.classList[payload ? 'add' : 'remove']('form-screen')
      // rootClassSet(payload, 'formScreen')
    },

    responsiveLayoutControl ({ commit, dispatch }) {
      const isXl = window.innerWidth >= 1280
      const isLg = window.innerWidth >= 1024
      const isMd = window.innerWidth >= 768
      const isSm = window.innerWidth < 768
      // console.log('responsiveLayoutControl', isXl, isLg, isMd, isSm, window.innerWidth)
      commit('layoutBreakpoints', { isXl, isLg, isMd, isSm })

      if (isXl) {
        dispatch('asideCompactToggle', true)
        dispatch('asideHiddenLayoutToggle', false)
      } else if (isLg) {
        dispatch('asideCompactToggle', false)
        dispatch('asideHiddenLayoutToggle', true)
      } else {
        dispatch('asideHiddenLayoutToggle', false)
      }
    },

    pushMessage ({ commit }, payload) {
      const ts = Date.now()
      const text = payload

      commit('pushMessage', {
        ts,
        text
      })
    },

    defaultLocale ({ commit, state }) {
      if (localStorage.getItem('locale') && localStorage.getItem('locale') !== 'undefined') {
        i18n.global.locale.value = localStorage.getItem('locale')
      } else {
        i18n.global.locale.value = 'nl'
        localStorage.setItem('locale', i18n.global.locale.value)
        // console.log('setting defaultLocale to nl')
        localStorage.setItem('locale', 'nl')
      }
    },

    defaultDarkMode ({ commit, state }) {
      let value = false

      if (localStorage.getItem('darkMode') && localStorage.getItem('darkMode') !== 'undefined') {
        if (localStorage.getItem('darkMode') === 'true') {
          value = true
        } else {
          value = false
        }

        document.documentElement.classList[value ? 'add' : 'remove']('dark')
        localStorage.setItem('darkMode', value)
        commit('basic', {
          key: 'darkMode',
          value
        })
      } else {
        localStorage.setItem('darkMode', state.darkMode)
      }
    },

    setLocale ({ commit }, locale) {
      localStorage.setItem('locale', locale)
      i18n.global.locale.value = locale
    },

    darkMode ({ commit, state }) {
      const value = !state.darkMode

      document.documentElement.classList[value ? 'add' : 'remove']('dark')
      localStorage.setItem('darkMode', value)

      commit('basic', {
        key: 'darkMode',
        value
      })
    }
  },
  getters: {
    getSyncValues: (state) => (payload) => {
      const syncId = payload.syncId
      const syncWhat = payload.syncWhat
      const syncValues = []

      if (Object.prototype.hasOwnProperty.call(state.syncedCharts, syncId) === false) {
        return false
      }

      // console.log(state.syncedCharts[syncId])
      Object.keys(state.syncedCharts[syncId]).forEach(function (key) {
        // Go over all dimensions and report if it matches syncWhat
        if (state.syncedCharts[syncId][key] !== null) {
          const syncDimensions = state.syncedCharts[syncId][key].syncDimensions

          if (syncDimensions !== undefined) {
            Object.keys(syncDimensions).forEach(function (dimension) {
              if (dimension === syncWhat && syncDimensions[dimension] !== null && syncDimensions[dimension] !== undefined) {
                syncValues.push(syncDimensions[dimension])
              }
            })
          }
        }
      })

      return syncValues
    }
  }
}
