import { createStore } from 'vuex'

import auth from './modules/auth'
import display from './modules/display'
import data from './modules/data'

export default createStore({
  modules: {
    auth,
    display,
    data
  }
})
